import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLocation,
  getLocationListByType,
  getLocationListLive,
  orderBook,
} from "../API";
import { toast } from "react-toastify";
import { useCallback } from "react";
import { useRef } from "react";
import { useFormik } from "formik";

import {
  Checkbox,
  FormControlLabel,
  Switch,
  useMediaQuery,
} from "@mui/material";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import { TfiReload } from "react-icons/tfi";
import { IoReloadCircleSharp } from "react-icons/io5";
import {
  IoIosCloseCircleOutline,
  IoIosCheckmarkCircleOutline,
} from "react-icons/io";
import { HiOutlinePhone } from "react-icons/hi";
import Loader from "./Loader";
import * as yup from "yup";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import RemoveOrderModal from "./RemoveOrderModal";
import RemoveTransitOrderModal from "./RemoveTransitOrderModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import TransitDetails from "./TransitDetails";
import ChangeLocationConfirmModal from "./ChangeLocationConfirmModal";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import { useLocation } from "react-router-dom";
import CancelOrderConfirmModal from "./CancelOrderConfirmModal";
import ChangeDropLocationConfirmModal from "./ChangeDropLocationConfirmModal";
import { storeRobotDetails } from "../redux/Actions";
import RobotCard from "./RobotCard";
import SummonCard from "./SummonCard";
import { useHasPermissionInAnyFleet } from "../customHooks/PermissionHooks";
import axiosInstance from "../config/axiosConfig";
function OrderListNew(props) {
  const hasPermissionForSummon = useHasPermissionInAnyFleet("summon");
  const hasPermissionForSummonStoreLocation = useHasPermissionInAnyFleet(
    "summon-store-location"
  );
  const hasPermissionForBookedOrders =
    useHasPermissionInAnyFleet("bookedOrders");
  const hasPermissionForBookOrdersFromDashboard = useHasPermissionInAnyFleet(
    "bookOrdersFromDashboard"
  );
  const hasPermissionForCancelOrders =
    useHasPermissionInAnyFleet("cancelOrders");
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  const [orderModalOpen, setorderModalOpen] = useState(false);
  const [orderlistData, setOrderlistData] = useState([]);
  // const [refresh, setrefresh] = useState(false);
  // const { fleetList } = useSelector((state) => state.fleetList);
  // const [orderaccepted, setorderaccepted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [preperationOrderData, setPreperationOrderData] = useState([]);
  const [dropLocationOptions, setDropLocationOptions] = useState([
    { value: "Custom", label: "Custom" },
  ]);
  const [pickupLocationOptions, setPickupLocationOptions] = useState([
    { value: "Custom", label: "Custom" },
  ]);
  const [preparedOrderData, setPreparedOrderData] = useState([]);
  const [editPreparationTime, setEditPreparationTime] = useState(null);
  const [editCancellationTime, setEditCancellationTime] = useState(null);
  const [updatedPreparationTime, setUpdatedPreparationTime] = useState("");
  const [updatedCancellationTime, setUpdatedCancellationTime] = useState("");
  const [robotData, setRobotData] = useState([]);
  const [suggestionsUpdatedTimeStamp, setSuggestionsUpdatedTimeStamp] =
    useState(null);
  const [openRemoveOrderModal, setOpenRemoveOrderModal] = useState(false);
  const [deletedOrderId, setDeletedOrderId] = useState("");
  const [openRemoveTransitOrder, setOpenRemoveTransitOrderModal] =
    useState(false);
  const [deletedTransitOrderId, setDeletedTransitOrderId] = useState("");
  const [confirmEditLocationModal, setConfirmEditLocationModal] =
    useState(false);
  const [confirmEditDropLocationModal, setConfirmEditDropLocationModal] =
    useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedDropLocation, setSelectedDropLocation] = useState();
  const [selectedOrderId, setSelectedOrderId] = useState();
  const [robotColors, setRobotColors] = useState([]);
  const [selectedCancelOrderId, setSelectedCancelOrderId] = useState();
  const [confirmationCancelOrder, setConfirmationCancelOrder] = useState(false);
  const [isPickupLocationEditedToggle, setIsPickupLocationEditedToggle] =
    useState(false);
  const [isDropLocationEditedToggle, setIsDropLocationEditedToggle] =
    useState(false);
  const [groupsColor, setGroupsColor] = useState({});
  const [groupsAssignedToRobots, setGroupsAssignedToRobots] = useState({});
  const [isSuggestionsLoading, setSuggestionsLoading] = useState(false);
  const audioRef = useRef(null);
  const audioBtn = useRef(null);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();
  const incomingData = useLocation();
  const emailId = localStorage.getItem("useremail");
  const { user, sessionDestroyed } = useSelector((state) => state.login);

  const fleetData = props.fleetData;

  const { startDate, endDate } = useSelector(
    (state) => state.datefilterreducer
  );

  const updateState = useCallback(async () => {
    if (!sessionDestroyed) {
      dispatch(fleetListAndRobotStatusByUserEmail(emailId));
    }
  }, [dispatch, emailId, sessionDestroyed]);

  const useFleetIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  const showOrderSizeOption = true;

  const tailwindColors = [
    "#FF0000", // Red
    "#FFA500", // Orange
    "#FFFF00", // Yellow
    "#FFFFFF", // White
    "#87CEEB", // Sky Blue
    "#FF69B4", // Hot Pink
    "#ADFF2F", // Green-Yellow
    "#008000", // Green
    "#00FF00", // Lime
    "#00CED1", // Dark Turquoise
    "#1E90FF", // Dodger Blue
    "#0000FF", // Blue
    "#8A2BE2", // Blue-Violet
    "#9400D3", // Dark Violet
    "#FF00FF", // Magenta
    "#FF4500", // Orange-Red
    "#8B008B", // Dark Magenta
    "#800080", // Purple
    "#A52A2A", // Brown
    "#8B4513", // Saddle Brown
    "#696969", // Dim Gray
    "#808080", // Gray
    "#A9A9A9", // Dark Gray
    "#000000", // Black
    "#DC143C", // Crimson
    "#FF6347", // Tomato
    "#FFD700", // Gold
    "#32CD32", // Lime Green
    "#20B2AA", // Light Sea Green
  ];

  // ...

  useEffect(() => {
    const createdGroupColors = {};
    const robotGroups = {};

    preparedOrderData.forEach((record, index) => {
      const { suggestedSchedule } = record || {};

      if (suggestedSchedule) {
        const { groupId, robotId } = suggestedSchedule;

        // Color Assignment
        if (!createdGroupColors[groupId]) {
          // Assign a new color only if it hasn't been assigned before
          const colorIndex =
            Object.keys(createdGroupColors).length % tailwindColors.length;
          createdGroupColors[groupId] = tailwindColors[colorIndex];
        }

        // Robot Groups
        if (!robotGroups[robotId]) {
          robotGroups[robotId] = new Set([groupId]);
        } else {
          robotGroups[robotId].add(groupId);
        }
      }
    });

    // Convert Set to Array
    const groupsAssignedToRobots = {};
    Object.keys(robotGroups).forEach((robotId) => {
      groupsAssignedToRobots[robotId] = Array.from(robotGroups[robotId]);
    });

    setGroupsAssignedToRobots(groupsAssignedToRobots);
    setGroupsColor(createdGroupColors);
  }, [preparedOrderData]);

  const transitOrderState = useCallback(async () => {
    if (!sessionDestroyed) {
      const orderData = await axiosInstance.post("order/transit", {
        fleetId: [props.fleetData ? props.fleetData._id : fleetData._id],
      });
      setOrderlistData(orderData?.data?.data?.order);
      setPreperationOrderData(orderData?.data?.data?.inPrepOrder);
      setPreparedOrderData(orderData?.data?.data?.prepOrder);
    }
  }, [sessionDestroyed, props.fleetData, fleetData._id]);

  const onValueChange = (value) => {
    formik.setFieldValue("phone", value);
  };

  const handleCloseConfirmEditLocationModal = () => {
    setConfirmEditLocationModal(false);
    setIsPickupLocationEditedToggle(false);
  };
  const handleCloseConfirmEditDeliveryLocationModal = () => {
    setConfirmEditDropLocationModal(false);
    setIsDropLocationEditedToggle(false);
  };

  useEffect(() => {
    const fleetId = props.fleetData ? props.fleetData._id : fleetData._id;
    dispatch(storeRobotDetails(fleetId));
  }, []);
  const changeOrderCancTime = async (id, isDelete) => {
    try {
      if (!isDelete && !updatedCancellationTime.length) {
        toast.error("Please input some value");
        return;
      }
      const deleteAction = await axiosInstance.post("order/noshow", {
        delay: isDelete ? null : updatedCancellationTime,
        orderId: id,
      });
      setUpdatedCancellationTime("");
      setEditCancellationTime(null);
      toast.success(deleteAction.data.message);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const rescheduleOrders = async () => {
    try {
      setSuggestionsLoading(true);
      const cancelAction = await axiosInstance.post(`order/reschedule`, {
        fleetId: props.fleetData ? props.fleetData._id : fleetData._id,
      });
      setSuggestionsUpdatedTimeStamp(new Date());
      toast.success("Suggestions Updated");
    } catch (err) {
      toast.error("Suggestions not updated");
    } finally {
      setSuggestionsLoading(false);
    }
  };
  function calculateTimeDifference(updatedTimeStamp) {
    const currentTimestamp = new Date().getTime();
    const updatedTimestamp = new Date(updatedTimeStamp).getTime();

    const differenceInMilliseconds = currentTimestamp - updatedTimestamp;
    const differenceInMinutes = Math.floor(
      differenceInMilliseconds / (1000 * 60)
    );

    return differenceInMinutes;
  }

  const handleChangeLocation = (orderId, selectedLocation, locationType) => {
    changeLocation(orderId, selectedLocation, locationType)
      .then((res) => {
        toast.success("Location changed successfully!");
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
        // toast.error("Something went wrong!");
      });
  };

  const locationPositionLabelPriority = (position) => {
    if (position && position.locationName) {
      return ``;
    }

    if (position && position.latitude) {
      return `(Lat,Lng)`;
    }

    if (position && position.positionX) {
      return "X,Y";
    }
  };

  function parseItem(item) {
    const [, stringPart = "", numberPart = 0] =
      /(^[a-zA-Z]*)(\d*)$/.exec(item) || [];
    return [stringPart, numberPart];
  }
  const updateRobotETA = useCallback(async () => {
    if (!sessionDestroyed) {
      return await axiosInstance
        .post(
          `getRobotsETA/${
            props.fleetData ? props.fleetData._id : fleetData._id
          }`
        )
        .then((res) => {
          setRobotData(
            res.data.result.sort((a, b) => {
              const [stringA, numberA] = parseItem(a.robotId);
              const [stringB, numberB] = parseItem(b.robotId);
              const comparison = stringA.localeCompare(stringB);
              return comparison === 0
                ? Number(numberA) - Number(numberB)
                : comparison;
            })
          );
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
          // toast.error("Something went wrong")
        });
      // console.log("Result",res.data.result.sort((a,b) => a.robotId - b.robotId))
    }
  }, [fleetData._id, props.fleetData, sessionDestroyed]);

  const playSound = async () => {
    if (audioRef.current) {
      try {
        await audioRef.current.play();
      } catch (error) {
        console.error("Failed to play audio:", error);
      }
    }
    // audioRef.current.play();
  };

  const stopSound = async () => {
    if (audioRef.current) {
      try {
        await audioRef.current.pause();
      } catch (error) {
        console.error("Failed to pause audio:", error);
      }
    }
    // audioRef.current.pause();
  };

  useEffect(() => {
    if (!sessionDestroyed) {
      getLocationListByType(
        props.fleetData ? props.fleetData._id : fleetData._id
      )
        .then((res) => {
          let drop_off_location = [];
          let pick_up_location = [];

          res?.data?.data.map((location) => {
            if (location._id === "DROPOFF") {
              location.locations.map((loc) => {
                drop_off_location.push({
                  value: JSON.stringify({
                    positionX: loc?.metricPositionX,
                    positionY: loc?.metricPositionY,
                    positionZ: loc?.metricPositionZ,
                    positionYaw: loc?.metricPositionYaw,
                    locationName: loc.locationName,
                    lat: loc?.latitude,
                    lon: loc?.longitude,
                    alt: loc?.altitude,
                    storeId: loc?.storeId,
                    latitude: loc?.latitude,
                    longitude: loc?.longitude,
                    headingAngle: loc?.headingAngle,
                  }),
                  label: loc.locationName,
                });
                return null;
              });
            }

            if (location._id === "PICKUP") {
              location.locations.map((loc) => {
                pick_up_location.push({
                  value: JSON.stringify({
                    positionX: loc?.metricPositionX,
                    positionY: loc?.metricPositionY,
                    positionZ: loc?.metricPositionZ,
                    positionYaw: loc?.metricPositionYaw,
                    locationName: loc.locationName,
                    lat: loc?.latitude,
                    lon: loc?.longitude,
                    alt: loc?.altitude,
                    latitude: loc?.latitude,
                    longitude: loc?.longitude,
                    storeId: loc?.storeId,
                    headingAngle: loc?.headingAngle,
                    dockingName: loc?.dockingName,
                  }),
                  label: loc.locationName,
                });
                return null;
              });
            }
            return null;
          });

          // console.log(client_location, "CLIENT");
          setDropLocationOptions([
            ...drop_off_location,
            { value: "Custom", label: "Custom" },
          ]);
          setPickupLocationOptions([
            ...pick_up_location,
            { value: "Custom", label: "Custom" },
          ]);
        })
        .catch((error) => {
          toast.error("Unable to fetch Location List");
        });
    }
  }, [fleetData._id, props.fleetData, sessionDestroyed]);

  useEffect(() => {
    // console.log(incomingData, "incomingData")
    if (
      incomingData &&
      incomingData?.state?.userData &&
      user &&
      Object.keys(user).length > 0
    ) {
      toast.success("Logged in Successfully!");
    }
  }, [user, incomingData]);

  useEffect(() => {
    if (
      (preperationOrderData.length > 0 ||
        orderlistData.length > 0 ||
        preparedOrderData.length > 0) &&
      audioRef &&
      audioRef.current
    ) {
      // if (!fleetData.preparationNeeded) {
      //   if (preparedOrderData && preparedOrderData.length > 0) {
      //     // console.log("PLAY SOUND PREPARED ORDER DATA");
      //     playSound();
      //   }
      //   // else {
      //   //   // console.log("PAUSE SOUND PREPARED ORDER DATA");
      //   //   stopSound();
      //   // }
      // }

      const anyPrepTimeLessThanZero = preperationOrderData.find((order) => {
        const preparationTimeStamp = new Date(order.preparationTime);
        const currentTimeStamp = Date.now();
        const timeDifferenceInMillis =
          preparationTimeStamp.getTime() - currentTimeStamp;
        const timeLeftInSeconds = Math.ceil(timeDifferenceInMillis / 1000);

        return timeLeftInSeconds <= 0;
      });

      const anyOrderWithFlag = orderlistData.find(
        (order) =>
          (order.noShowFlagPickup === 1 && order.statusCode === 4) ||
          (order.noShowFlagDelivery === 1 && order.statusCode === 8)
      );

      if (
        anyPrepTimeLessThanZero ||
        anyOrderWithFlag ||
        (!fleetData.preparationNeeded &&
          preparedOrderData &&
          preparedOrderData.length > 0)
      ) {
        // audioBtn.current.click();
        playSound();
      } else {
        stopSound();
      }
    }
  }, [
    fleetData.preparationNeeded,
    orderlistData,
    preparedOrderData,
    preperationOrderData,
    audioRef,
  ]);

  const cancelCurrentAction = async (actionId, robotId) => {
    try {
      const cancelAction = await axiosInstance.post(`robot/cancelActionByID`, {
        robotId,
        actionId,
      });
      toast.success(cancelAction.data.message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();
    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useFleetIntervalAsync(updateState, 5000);
  useIntervalAsync(transitOrderState, 5000);
  useIntervalAsync(updateRobotETA, 1000 * 2);

  // useEffect(() => {
  // if (
  //   (preperationOrderData.length > 1 || orderlistData.length > 1) &&
  //   audioRef &&
  //   audioRef.current
  // ) {
  //   const anyPrepTimeLessThanZero = preperationOrderData.find((order) => {
  //     const preparationTimeStamp = new Date(order.preparationTime);
  //     const currentTimeStamp = Date.now();
  //     const timeDifferenceInMillis =
  //       preparationTimeStamp.getTime() - currentTimeStamp;
  //     const timeLeftInSeconds = Math.ceil(timeDifferenceInMillis / 1000);

  //     return timeLeftInSeconds <= 0;
  //   });

  //   const anyOrderWithFlag = orderlistData.find(
  //     (order) => order?.noShowFlagPickup === 1 || order?.noShowFlagDelivery === 1
  //   );

  //   if (anyPrepTimeLessThanZero || anyOrderWithFlag) {
  //     playSound();
  //   } else {
  //     stopSound();
  //   }
  // }
  // }, [orderlistData, preperationOrderData]);

  // const handleChangetwo = (event, value) => {
  //   setpage(value);
  //   setcurrentpage(value);
  // };

  const editOrderPrepTime = (id) => {
    setUpdatedPreparationTime("");
    setEditPreparationTime(id);
  };

  const deleteOrder = async (id) => {
    try {
      const deleteAction = await axiosInstance.post(`order/cancel`, {
        fleetId: props.fleetData ? props.fleetData._id : fleetData._id,
        orderId: id,
        message: "order removed",
      });
      toast.success(deleteAction.data.message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const deleteOrderTransit = async (id) => {
    try {
      const deleteAction = await axiosInstance.post(`order/cancel`, {
        fleetId: props.fleetData ? props.fleetData._id : fleetData._id,
        orderId: id,
        message: "order removed",
      });
      toast.success(deleteAction.data.message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleCancellationInputChange = (event) => {
    if (!isNaN(Number(event.target.value))) {
      if (Number(event.target.value) >= 3 && Number(event.target.value) <= 60) {
        setUpdatedCancellationTime(event.target.value);
      } else {
        toast.error(
          "The timer value should be between 3 seconds to 60 seconds!!"
        );
      }
    }

    // if (!/^\d{0,2}$/.test(event.target.value)) {
    //   return false;
    // } else if (!isNaN(event.target.value) && event.target.value >= 0) {
    //   setUpdatedCancellationTime(event.target.value);
    // }
  };

  const modalstyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "var(--primaryColor)",
    border: "2px solid #000",
    boxShadow: 24,
  };

  const validationSchema = yup.object({
    // orderId: yup
    //   .string()
    //   .typeError("Order ID must be a string")
    //   .required("Order ID is required.")
    //   .matches(/^[0-9a-zA-Z-]*$/, "Order ID not allowed"),
    orderSize: yup.string().required("Size is req."),
    deliveryLocation: yup.string().required("Location is req."),
    preparationTime: yup.string().required("time required"),
    // phone: yup
    //   .string()
    //   .required("phone required")
    //   .min(11, "Phone number must be at least 11 characters"),
  });

  const formik = useFormik({
    initialValues: {
      orderId: "",
      orderSize: `{"height": 10, "length": 10 , "width": 10, "weight": 10}`,
      deliveryLocation: "",
      pickupLocation: "",
      height: "",
      width: "",
      length: "",
      weight: "",
      positionX: "",
      positionY: "",
      positionYaw: "",
      preparationTime: "10",
      phone: "",
      orderType: "LIVE",
      alreadyPrepared: false,
      robotId: "",
      autoLoad: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      orderBook(
        values,
        props.fleetData ? props.fleetData._id : fleetData._id,
        fleetData
      )
        .then((res) => {
          if (res?.status === 200) {
            setLoader(false);
            setorderModalOpen(false);
            toast.success(res.data.message);
            resetForm({ values: "" });
          } else {
            setLoader(false);
            setorderModalOpen(false);
            toast.error(res.data.message);
            // toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
          setorderModalOpen(false);
          toast.error(err.message);
        });
      setLoader(true);
    },
  });

  const assignOrder = (event) => {
    setorderModalOpen(true);
  };

  const orderModalClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setorderModalOpen(false);
    formik.resetForm();
  };

  const changeOrderPrepTime = async (id) => {
    try {
      if (!updatedPreparationTime.length) {
        toast.error("Please input some value");
        return;
      }
      const deleteAction = await axiosInstance.post(`order/edit`, {
        prepTime: updatedPreparationTime,
        orderId: id,
      });
      setUpdatedPreparationTime("");
      setEditPreparationTime(null);
      toast.success(deleteAction.data.message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const moveOrderToNextStep = async (id) => {
    try {
      await axiosInstance.post(`order/preparationStatus`, {
        preparationStatusCode: 1,
        orderId: id,
      });
      toast.success("Order moved");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const calculateTimeLeft = (order) => {
    const preparationTimeStamp = new Date(order.preparationTime);
    const currentTimeStamp = Date.now();
    const timeDifferenceInMillis =
      preparationTimeStamp.getTime() - currentTimeStamp;
    const timeLeftInSeconds = Math.ceil(timeDifferenceInMillis / 1000);
    return timeLeftInSeconds > 0
      ? Math.ceil(timeLeftInSeconds / 60) + " min"
      : "Prepared";
  };

  const locationPositionValuePriority = (position) => {
    if (position && position.locationName) {
      return position.locationName;
    }

    if (position && position.latitude) {
      return `${position.latitude}, ${position.longitude}`;
    }

    if (position && position.positionX) {
      return `${position.positionX}, ${position.positionY}`;
    }
  };

  return (
    <>
      {hasPermissionForBookOrdersFromDashboard && (
        <>
          {/* <button className="absolute right-2 inset-y-0 px-2.5 py-0.5 rounded-md bg-ottonomyBlue"><p class="text-textColor text-md" onClick={assignOrder}>Add New Order</p></button> */}
          <button
            className="z-20 mb-3 md:mb-0 m-auto font-bold btn Assign-btn hover:shadow-md"
            onClick={assignOrder}
          >
            Add New Order
          </button>
          <Modal
            open={orderModalOpen}
            onHide={orderModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            backdrop="static"
            keyboard="false"
          >
            <Box
              sx={{
                ...modalstyle1,
                width: isMobile ? "100%" : 1000,
                height: isMobile ? "100%" : "auto",
                border: 0,
                borderRadius: "8px",
              }}
            >
              {loader ? (
                <div className="flex flex-col items-center justify-center p-4">
                  <Loader />
                  <span className="text-textColor">Booking Order...</span>
                </div>
              ) : (
                <div className="Order_modal_wrapper">
                  <div className="p-4 d-flex-center OrderModal_heading_wrapper">
                    <h3 className="Order_modal_h3"> Add New Order</h3>
                  </div>
                  <div />

                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="AddFleet_form_field_wrapper addlocation_textfield form__control__wrapper flex-wrap gap-4 px-2 sm:px-5 max-h-[calc(100vh-250px)] sm:max-h-[250px] overflow-scroll">
                        {fleetData?.booking &&
                          fleetData?.booking?.customerMobileNumber && (
                            <div
                              className="w-[100%] sm:w-[30%]"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <label
                                style={{
                                  marginLeft: "0px",
                                  fontWeight: "600",
                                }}
                              >
                                Phone Number*
                              </label>
                              <div
                                className="inline"
                                style={{
                                  width: "100%",
                                  margin: "auto",
                                }}
                              >
                                <PhoneInput
                                  style={{
                                    textAlign: "justify",
                                    fontSize: "14px",
                                    position: "relative",
                                  }}
                                  country={"us"}
                                  specialLabel="Phone Number"
                                  enableSearch={true}
                                  autoFocus={true}
                                  value={formik.values.phone}
                                  onChange={(value) => onValueChange(value)}
                                  countryCodeEditable={false}
                                  margin="standard"
                                  name="phone"
                                  label="Phone Number"
                                  required
                                  containerStyle={{}}
                                  inputStyle={{
                                    padding: "8px 60px",
                                    border: "0",
                                    borderRadius: "0",
                                    width: "100%",
                                  }}
                                  error={
                                    formik.touched.phone &&
                                    Boolean(formik.errors.phone)
                                  }
                                  helperText={
                                    formik.touched.phone && formik.errors.phone
                                  }
                                  dropdownStyle={{
                                    position: "fixed",
                                  }}
                                />
                              </div>
                              <div>
                                {" "}
                                {formik.errors.phone && formik.touched.phone ? (
                                  <p
                                    style={{
                                      color: "#d32f2f",
                                      fontSize: "0.75rem",
                                      marginTop: "-2px",
                                      textAlign: "left",
                                      paddingLeft: "0px",
                                    }}
                                  >
                                    Please Enter Phone Number
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          )}

                        {fleetData?.booking && fleetData?.booking?.orderId && (
                          <div
                            className="w-[100%] sm:w-[30%]"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <label
                              style={{
                                marginLeft: "0px",
                                fontWeight: "600",
                              }}
                            >
                              Order ID*
                            </label>
                            <TextField
                              id="standard-size-normal"
                              fullWidth
                              value={formik.values.orderId}
                              name="orderId"
                              onChange={formik.handleChange}
                              error={
                                formik.touched.orderId &&
                                Boolean(formik.errors.orderId)
                              }
                              helperText={
                                formik.touched.orderId && formik.errors.orderId
                              }
                            />
                          </div>
                        )}
                        {fleetData?.booking &&
                          fleetData?.booking?.customerName && (
                            <div
                              className="w-[100%] sm:w-[30%]"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <label
                                style={{
                                  marginLeft: "0px",
                                  fontWeight: "600",
                                }}
                              >
                                Customer Name*
                              </label>
                              <TextField
                                id="standard-size-normal"
                                fullWidth
                                value={formik.values.customerName}
                                name="customerName"
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.customerName &&
                                  Boolean(formik.errors.customerName)
                                }
                                helperText={
                                  formik.touched.customerName &&
                                  formik.errors.customerName
                                }
                              />
                            </div>
                          )}
                        {fleetData?.booking &&
                          fleetData?.booking?.customerEmail && (
                            <div
                              className="w-[100%] sm:w-[30%]"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <label
                                style={{
                                  marginLeft: "0px",
                                  fontWeight: "600",
                                }}
                              >
                                Customer Email
                              </label>
                              <TextField
                                id="standard-size-normal"
                                fullWidth
                                value={formik.values.customerEmail}
                                name="customerEmail"
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.customerEmail &&
                                  Boolean(formik.errors.customerEmail)
                                }
                                helperText={
                                  formik.touched.customerEmail &&
                                  formik.errors.customerEmail
                                }
                              />
                            </div>
                          )}
                        {fleetData?.booking && fleetData?.booking?.robotId && (
                          <div
                            className="w-[40%] sm:w-[30%]"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <label
                              style={{
                                marginLeft: "0px",
                                fontWeight: "600",
                              }}
                            >
                              Robot
                            </label>
                            <FormControl
                              className="bg-bodycolor text-textColor"

                              // style={{
                              //   backgroundColor: "rgb(28, 30, 43)",
                              //   WebkitTextFillColor:
                              //     "rgb(189, 196, 224) !important",
                              // }}
                            >
                              <Select
                                variant="standard"
                                labelId="robot-label"
                                id="robot-select"
                                name="robotId"
                                value={
                                  formik.values.robotId === ""
                                    ? "Select Robot"
                                    : formik.values.robotId
                                }
                                label="robot"
                                style={{
                                  color: "var(--textColor)",
                                  margin: "0",
                                  padding: "5px 5px 5px 10px",
                                  fontSize: "14px",
                                }}
                                error={
                                  formik.values.robotId === "" &&
                                  Boolean(formik.errors.robotId)
                                }
                                onChange={formik.handleChange}
                              >
                                <MenuItem
                                  value="Select Robot"
                                  selected
                                  disabled
                                >
                                  Select Robot
                                </MenuItem>
                                {robotData?.map((robot, index) => {
                                  return (
                                    <MenuItem key={index} value={robot.robotId}>
                                      {robot?.displayRobotName}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <div>
                              {" "}
                              {formik.errors.robotId &&
                              formik.touched.robotId ? (
                                <p
                                  style={{
                                    color: "#d32f2f",
                                    fontSize: "0.75rem",
                                    marginTop: "-2px",
                                    textAlign: "left",
                                    paddingLeft: "0px",
                                  }}
                                >
                                  Robot is required
                                </p>
                              ) : null}
                            </div>
                          </div>
                        )}
                        {fleetData?.booking &&
                          fleetData?.booking?.pickup?.length > 0 && (
                            <>
                              <div
                                className="w-[100%] sm:w-[30%]"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "4px",
                                }}
                              >
                                <label
                                  style={{
                                    marginLeft: "0px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Select Pickup Location
                                </label>
                                <FormControl
                                  style={{
                                    backgroundColor:
                                      "bg-bodycolor text-textColor",
                                    // WebkitTextFillColor:
                                    //   "rgb(189, 196, 224) !important",
                                  }}
                                >
                                  <Autocomplete
                                    options={pickupLocationOptions}
                                    // error={
                                    //   formik.touched.orderId &&
                                    //   Boolean(formik.errors.orderId)
                                    // }
                                    helperText={
                                      formik.touched.pickupLocation &&
                                      formik.errors.pickupLocation
                                    }
                                    value={
                                      formik.values.pickupLocation === ""
                                        ? null
                                        : pickupLocationOptions.find(
                                            (option) =>
                                              option.value ===
                                              formik.values.pickupLocation
                                          ) || null
                                    }
                                    onChange={(_, selectedOption) => {
                                      formik.setFieldValue(
                                        "pickupLocation",
                                        selectedOption
                                          ? selectedOption.value
                                          : ""
                                      );
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.value === value
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        style={{
                                          color: "rgb(189, 196, 224)",
                                          margin: "0",
                                          padding: "5px 5px 5px 10px",
                                          fontSize: "14px",
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                                <div>
                                  {" "}
                                  {formik.errors.pickupLocation &&
                                  formik.touched.pickupLocation ? (
                                    <p
                                      style={{
                                        color: "#d32f2f",
                                        fontSize: "0.75rem",
                                        marginTop: "-2px",
                                        textAlign: "left",
                                        paddingLeft: "0px",
                                      }}
                                    >
                                      Pickup Location is req.
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              {formik.values.pickupLocation === "Custom" && (
                                <>
                                  {fleetData?.booking?.pickup.map(
                                    (pickupOptions, index) => (
                                      <div
                                        className="w-[40%] sm:w-[20%] capitalize"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "4px",
                                        }}
                                      >
                                        <label
                                          style={{
                                            marginLeft: "0px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {pickupOptions.split("-").join(" ")}
                                        </label>
                                        <TextField
                                          id="standard-size-normal"
                                          variant="standard"
                                          value={
                                            formik.values.pickupLocation[
                                              pickupOptions
                                            ]
                                          }
                                          name={pickupOptions}
                                          type="text"
                                          onChange={formik.handleChange}
                                          style={{ fontSize: "14px" }}
                                          placeholder={pickupOptions}
                                          error={
                                            formik.touched.pickupLocation &&
                                            Boolean(
                                              formik.errors.pickupLocation
                                            )
                                          }
                                          required
                                          helperText={
                                            formik.touched.pickupLocation &&
                                            formik.errors.pickupLocation
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                </>
                              )}
                            </>
                          )}

                        {fleetData?.booking &&
                          fleetData?.booking?.delivery?.length > 0 && (
                            <>
                              <div
                                className="w-[100%] sm:w-[30%]"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "4px",
                                }}
                              >
                                <label
                                  style={{
                                    marginLeft: "0px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Select Drop Location
                                </label>
                                <FormControl className="bg-bodycolor text-textColor">
                                  <Autocomplete
                                    options={dropLocationOptions}
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        color: "var(--textColor)", // Changes input text color
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "var(--textColor)", // Changes label color
                                      },
                                    }}
                                    // error={
                                    //   formik.touched.orderId &&
                                    //   Boolean(formik.errors.orderId)
                                    // }
                                    helperText={
                                      formik.touched.deliveryLocation &&
                                      formik.errors.deliveryLocation
                                    }
                                    value={
                                      formik.values.deliveryLocation === ""
                                        ? null
                                        : dropLocationOptions.find((option) => {
                                            return (
                                              option.value ===
                                              formik.values.deliveryLocation
                                            );
                                          }) || null
                                    }
                                    onChange={(_, selectedOption) => {
                                      formik.setFieldValue(
                                        "deliveryLocation",
                                        selectedOption
                                          ? selectedOption.value
                                          : ""
                                      );
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.value === value
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        style={{
                                          color: "rgb(189, 196, 224)",
                                          margin: "0",
                                          padding: "5px 5px 5px 10px",
                                          fontSize: "14px",
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                                <div>
                                  {" "}
                                  {formik.errors.deliveryLocation &&
                                  formik.touched.deliveryLocation ? (
                                    <p
                                      style={{
                                        color: "#d32f2f",
                                        fontSize: "0.75rem",
                                        marginTop: "-2px",
                                        textAlign: "left",
                                        paddingLeft: "0px",
                                      }}
                                    >
                                      Drop Location is req.
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              {formik.values.deliveryLocation === "Custom" && (
                                <>
                                  {fleetData?.booking?.delivery.map(
                                    (deliveryOptions, index) => (
                                      <div
                                        key={"delivery__options__" + index}
                                        className="w-[40%] sm:w-[20%] capitalize"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "4px",
                                        }}
                                      >
                                        <label
                                          style={{
                                            marginLeft: "0px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {deliveryOptions.split("-").join(" ")}
                                        </label>
                                        <TextField
                                          id="standard-size-normal"
                                          variant="standard"
                                          value={
                                            formik.values.deliveryLocation[
                                              deliveryOptions
                                            ]
                                          }
                                          name={deliveryOptions}
                                          type="text"
                                          onChange={formik.handleChange}
                                          style={{ fontSize: "14px" }}
                                          placeholder={deliveryOptions}
                                          error={
                                            formik.touched.deliveryLocation &&
                                            Boolean(
                                              formik.errors.deliveryLocation
                                            )
                                          }
                                          required
                                          helperText={
                                            formik.touched.deliveryLocation &&
                                            formik.errors.deliveryLocation
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                </>
                              )}
                            </>
                          )}

                        {fleetData?.booking &&
                          fleetData?.booking?.orderType && (
                            <div
                              className="w-[40%] sm:w-[30%]"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <label
                                style={{
                                  marginLeft: "0px",
                                  fontWeight: "600",
                                }}
                              >
                                Order Type
                              </label>
                              <FormControl
                                style={{
                                  backgroundColor: "rgb(28, 30, 43)",
                                  WebkitTextFillColor:
                                    "rgb(189, 196, 224) !important",
                                }}
                              >
                                <Select
                                  variant="standard"
                                  labelId="orderType-label"
                                  id="orderType-select"
                                  name="orderType"
                                  value={
                                    formik.values.orderType === ""
                                      ? "Select Order type"
                                      : formik.values.orderType
                                  }
                                  label="orderType"
                                  style={{
                                    color: "rgb(189, 196, 224)",
                                    margin: "0",
                                    padding: "5px 5px 5px 10px",
                                    fontSize: "14px",
                                  }}
                                  error={
                                    formik.values.orderType === "" &&
                                    Boolean(formik.errors.orderType)
                                  }
                                  onChange={formik.handleChange}
                                >
                                  <MenuItem
                                    value="Select Order Type"
                                    selected
                                    disabled
                                  >
                                    Select Order Type
                                  </MenuItem>
                                  <MenuItem value="LIVE">LIVE ORDER</MenuItem>
                                  <MenuItem value="TEST">TEST ORDER</MenuItem>
                                </Select>
                              </FormControl>
                              <div>
                                {" "}
                                {formik.errors.orderType &&
                                formik.touched.orderType ? (
                                  <p
                                    style={{
                                      color: "#d32f2f",
                                      fontSize: "0.75rem",
                                      marginTop: "-2px",
                                      textAlign: "left",
                                      paddingLeft: "0px",
                                    }}
                                  >
                                    Order Type is req.
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          )}

                        {fleetData?.booking &&
                          fleetData?.booking?.orderDetails?.length > 0 && (
                            <>
                              <div
                                className="w-[40%] sm:w-[30%]"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "4px",
                                }}
                              >
                                <label
                                  style={{
                                    marginLeft: "0px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Order Size
                                </label>
                                <FormControl
                                  style={{
                                    backgroundColor: "rgb(28, 30, 43)",
                                    WebkitTextFillColor:
                                      "rgb(189, 196, 224) !important",
                                  }}
                                >
                                  <Select
                                    variant="standard"
                                    labelId="orderSize-label"
                                    id="orderSize-select"
                                    name="orderSize"
                                    value={
                                      formik.values.orderSize === ""
                                        ? "Select Order Size"
                                        : formik.values.orderSize
                                    }
                                    label="orderSize"
                                    style={{
                                      color: "rgb(189, 196, 224)",
                                      margin: "0",
                                      padding: "5px 5px 5px 10px",
                                      fontSize: "14px",
                                    }}
                                    error={
                                      formik.values.orderSize === "" &&
                                      Boolean(formik.errors.orderSize)
                                    }
                                    onChange={formik.handleChange}
                                  >
                                    <MenuItem
                                      value="Select Order Size"
                                      selected
                                      disabled
                                    >
                                      Select Order Size
                                    </MenuItem>
                                    {fleetData?.booking?.orderDetails.includes(
                                      "default"
                                    ) && (
                                      <MenuItem
                                        value={`{"height": 10, "length":10 , "width": 10, "weight": 10}`}
                                      >
                                        Default Cabin
                                      </MenuItem>
                                    )}
                                    <MenuItem value={`Small`}>Small</MenuItem>
                                    <MenuItem value={`Large`}>Large</MenuItem>
                                    <MenuItem value={`Custom`}>Custom</MenuItem>
                                  </Select>
                                </FormControl>
                                <div>
                                  {" "}
                                  {formik.errors.orderSize &&
                                  formik.touched.orderSize ? (
                                    <p
                                      style={{
                                        color: "#d32f2f",
                                        fontSize: "0.75rem",
                                        marginTop: "-2px",
                                        textAlign: "left",
                                        paddingLeft: "0px",
                                      }}
                                    >
                                      Order Size is req.
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              {formik.values.orderSize === "Custom" && (
                                <>
                                  {fleetData?.booking.orderDetails.map(
                                    (orderDetail, index) => (
                                      <Fragment
                                        key={"order__details__custom__" + index}
                                      >
                                        {orderDetail !== "default" && (
                                          <div
                                            className="w-[40%] sm:w-[20%] capitalize"
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "4px",
                                            }}
                                          >
                                            <label
                                              style={{
                                                marginLeft: "0px",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {orderDetail}
                                            </label>
                                            <TextField
                                              id="standard-size-normal"
                                              variant="standard"
                                              value={formik.values[orderDetail]}
                                              name={orderDetail}
                                              type="number"
                                              onChange={formik.handleChange}
                                              style={{ fontSize: "14px" }}
                                              placeholder={orderDetail}
                                              error={
                                                formik.touched[orderDetail] &&
                                                Boolean(
                                                  formik.errors[orderDetail]
                                                )
                                              }
                                              required
                                              helperText={
                                                formik.touched[orderDetail] &&
                                                formik.errors[orderDetail]
                                              }
                                            />
                                          </div>
                                        )}
                                      </Fragment>
                                    )
                                  )}
                                  {/* <div
                                    className="w-[40%] sm:w-[20%]"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "4px",
                                    }}
                                  >
                                    <label
                                      style={{
                                        marginLeft: "0px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Height
                                    </label>
                                    <TextField
                                      id="standard-size-normal"
                                      variant="standard"
                                      value={formik.values.height}
                                      name="height"
                                      type="number"
                                      onChange={formik.handleChange}
                                      style={{ fontSize: "14px" }}
                                      placeholder="Height"
                                      error={
                                        formik.touched.height &&
                                        Boolean(formik.errors.height)
                                      }
                                      required
                                      helperText={
                                        formik.touched.height &&
                                        formik.errors.height
                                      }
                                    />
                                  </div>
                                  <div
                                    className="w-[40%] sm:w-[20%]"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "4px",
                                    }}
                                  >
                                    <label
                                      style={{
                                        marginLeft: "0px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Length
                                    </label>
                                    <TextField
                                      id="standard-size-normal"
                                      variant="standard"
                                      value={formik.values.length}
                                      name="length"
                                      type="number"
                                      onChange={formik.handleChange}
                                      style={{ fontSize: "14px" }}
                                      placeholder="Length"
                                      error={
                                        formik.touched.length &&
                                        Boolean(formik.errors.length)
                                      }
                                      required
                                      helperText={
                                        formik.touched.length &&
                                        formik.errors.length
                                      }
                                    />
                                  </div>
                                  <div
                                    className="w-[40%] sm:w-[20%]"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "4px",
                                    }}
                                  >
                                    <label
                                      style={{
                                        marginLeft: "0px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Width
                                    </label>
                                    <TextField
                                      id="standard-size-normal"
                                      variant="standard"
                                      value={formik.values.width}
                                      name="width"
                                      type="number"
                                      onChange={formik.handleChange}
                                      style={{ fontSize: "14px" }}
                                      placeholder="Width"
                                      error={
                                        formik.touched.width &&
                                        Boolean(formik.errors.width)
                                      }
                                      required
                                      helperText={
                                        formik.touched.width &&
                                        formik.errors.width
                                      }
                                    />
                                  </div>
                                  <div
                                    className="w-[40%] sm:w-[20%]"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "4px",
                                    }}
                                  >
                                    <label
                                      style={{
                                        marginLeft: "0px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Weight
                                    </label>
                                    <TextField
                                      id="standard-size-normal"
                                      variant="standard"
                                      value={formik.values.weight}
                                      name="weight"
                                      type="number"
                                      onChange={formik.handleChange}
                                      style={{ fontSize: "14px" }}
                                      placeholder="Weight"
                                      error={
                                        formik.touched.weight &&
                                        Boolean(formik.errors.weight)
                                      }
                                      required
                                      helperText={
                                        formik.touched.weight &&
                                        formik.errors.weight
                                      }
                                    />
                                  </div> */}
                                </>
                              )}
                            </>
                          )}

                        {fleetData?.booking &&
                          fleetData?.booking?.preparationTime && (
                            <div
                              className="w-[40%] sm:w-[30%]"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <div className="flex items-center justify-between w-full">
                                <label
                                  style={{
                                    marginLeft: "0px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Prep Time (mins)
                                </label>
                                <FormControlLabel
                                  sx={{
                                    "& .MuiFormControlLabel-label": {
                                      fontSize: "11px",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 16,
                                    },
                                    "& .MuiButtonBase-root-MuiCheckbox-root": {
                                      padding: "0px",
                                    },
                                  }}
                                  value={formik.values.alreadyPrepared}
                                  name="alreadyPrepared"
                                  onChange={formik.handleChange}
                                  control={<Checkbox />}
                                  label="Already Prepared"
                                />
                              </div>
                              <TextField
                                id="standard-size-normal"
                                variant="standard"
                                disabled={formik.values.alreadyPrepared}
                                value={formik.values.preparationTime}
                                name="preparationTime"
                                onChange={formik.handleChange}
                                style={{ fontSize: "14px" }}
                                error={
                                  formik.touched.preparationTime &&
                                  Boolean(formik.errors.preparationTime)
                                }
                                type="number"
                                helperText={
                                  formik.touched.preparationTime &&
                                  formik.errors.preparationTime
                                }
                              />
                            </div>
                          )}
                        {fleetData?.booking &&
                          fleetData?.booking?.showAutoLoadToggle && (
                            <div className="flex gap-x-1 col-span-2 items-center">
                              <label className="block text-textColor font-bold">
                                Autoload Order
                              </label>
                              <Switch
                                name="autoLoad"
                                checked={formik.values.autoLoad}
                                onChange={formik.handleChange}
                                inputProps={{
                                  "aria-label": "Auto Load Switch",
                                }}
                                style={{ color: "#fff" }}
                              />
                            </div>
                          )}
                      </div>

                      <div className="absolute inset-x-0 bottom-0 flex justify-between px-3 Order_modal_button_wrapper sm:relative">
                        <button
                          onClick={orderModalClose}
                          className="!p-[10px_20px] m-[20px] font-bold transition-all border-2 rounded-lg bg-ottonomyDarkBlue border-ottonomyBlue hover:bg-ottonomyBlue text-white"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="transition-all Order_save_button text-white hover:bg-ottonomyBlue"
                        >
                          Submit{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Box>
          </Modal>
        </>
      )}
      <div className="grid grid-cols-8 gap-3 h-full">
        {/* <input ref={audioBtn} className="absolute" /> */}
        <audio ref={audioRef}>
          <source src="/assets/audio/alert.wav" type="audio/wav"></source>
        </audio>
        {/* Robot card */}
        <div className="grid col-span-full md:col-span-3 text-textColor bg-primaryColor p-3 rounded-lg shadow-md">
          <div className="relative flex flex-col gap-2 min-h-[400px] max-h-[400px] overflow-auto">
            <span className="sticky top-0  pb-2 flex items-center text-lg font-semibold font-poppins">
              Robots
            </span>

            {robotData && robotData?.length !== 0 ? (
              <>
                {robotData.map((robot, index) => (
                  <RobotCard
                    key={index}
                    index={index}
                    robot={robot}
                    selectedLocation={selectedLocation}
                    showSummonControls={false}
                    playSound={playSound}
                    stopSound={stopSound}
                  />
                ))}
              </>
            ) : (
              <div className="text-textColor font-semibold flex items-center justify-center w-full h-full ">
                {" "}
                No Robot to show
              </div>
            )}
          </div>
        </div>

        {/* Robot summon card */}
        {(hasPermissionForSummon || hasPermissionForSummonStoreLocation) && (
          <div className="grid col-span-full md:col-span-5 text-textColor bg-primaryColor p-3 rounded-lg shadow-md">
            {/* <>{console.log(pickupLocationOptions[0])}</> */}
            <SummonCard
              pickupLocations={pickupLocationOptions}
              fleetId={props.fleetData ? props.fleetData._id : fleetData._id}
              robotData={robotData}
            />
          </div>
        )}

        {hasPermissionForBookedOrders && fleetData.preparationNeeded ? (
          <div className="grid col-span-full md:col-span-3 text-textColor bg-primaryColor p-3 rounded-lg shadow-md">
            <div className="flex flex-col gap-2 min-h-[400px] max-h-[400px] overflow-auto">
              <div className="flex items-center gap-3 pb-1 border-b border-b-ottonomyBlue">
                {preperationOrderData && preperationOrderData?.length > 0 && (
                  <span className="text-3xl font-semibold font-poppins text-ottonomyBlue">
                    {preperationOrderData.length}
                  </span>
                )}
                <span className="text-lg font-semibold font-poppins flex">
                  <p>Orders Under Preparation</p>
                </span>
              </div>
              <div className="flex flex-col w-full h-full gap-2 overflow-auto ">
                {preperationOrderData && preperationOrderData.length !== 0 ? (
                  <table>
                    <thead className="text-[rgb(255,255,255,0.7)] text-sm border-b !border-b-[rgb(255,255,255,0.16)]">
                      <tr>
                        <th className="p-2">Order ID</th>
                        <th className="p-2">Prep. Time</th>
                        <th className="p-2">Delivery Point</th>
                        <th className="p-2"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {preperationOrderData?.map((prepOrder, index) => {
                        return (
                          <tr
                            key={"preparation__order__no__" + index}
                            className={`${
                              index !== preperationOrderData?.length - 1 &&
                              "border-b border-[rgba(255,255,255,0.16)]"
                            }`}
                          >
                            <td className="px-2 py-[10px]">
                              <div className="flex flex-col justify-center h-full">
                                <span className="text-sm font-semibold leading-4">
                                  {prepOrder.orderId}
                                </span>
                                <span className="text-[11px] font-extralight leading-4">
                                  {new Date(prepOrder.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      timeZone:
                                        fleetData?.map?.timeZone ??
                                        "America/New_York",
                                      timeZoneName: "short",
                                    }
                                  )}{" "}
                                </span>
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="flex items-center gap-3">
                                <span className="font-semibold text-sm text-[#E7E703]">
                                  {calculateTimeLeft(prepOrder)}
                                </span>
                                {editPreparationTime !== prepOrder.orderId && (
                                  <div className="flex items-center gap-1">
                                    <TbEdit
                                      className="w-5 h-5 cursor-pointer text-ottonomyBlue"
                                      onClick={() =>
                                        editOrderPrepTime(prepOrder.orderId)
                                      }
                                    />
                                    <IoIosCheckmarkCircleOutline
                                      className="w-5 h-5 cursor-pointer text-ottonomyBlue"
                                      onClick={() =>
                                        moveOrderToNextStep(prepOrder.orderId)
                                      }
                                    />
                                  </div>
                                )}
                                {editPreparationTime === prepOrder.orderId && (
                                  <div className="flex flex-col items-center justify-center h-full gap-1">
                                    <div className="flex items-center justify-start w-full h-2 px-1">
                                      <span className="text-[9px]">
                                        Min - 3
                                      </span>
                                    </div>
                                    <div className="flex items-center gap-0.5 grow-0 shrink basis-auto ">
                                      <span className="flex items-center justify-center h-full text-sm font-bold bg-transparent border rounded-md">
                                        <div className="flex items-center w-16 h-5 px-2">
                                          <input
                                            type="number"
                                            value={updatedPreparationTime}
                                            onChange={(event) => {
                                              if (
                                                // !/^$|^(3|[4-9]|[1-5]\d|60)$/.test(
                                                //   event.target.value
                                                // )
                                                !/^\d{0,2}$/.test(
                                                  event.target.value
                                                )
                                              ) {
                                                return false;
                                              } else {
                                                setUpdatedPreparationTime(
                                                  event.target.value
                                                );
                                              }
                                            }}
                                            className="w-full h-full bg-transparent focus:outline-none"
                                          />
                                          <span className="text-xs text-textColor font-poppins">
                                            min
                                          </span>
                                        </div>
                                      </span>
                                      <span
                                        className="w-5 h-5 cursor-pointer"
                                        onClick={() => {
                                          if (
                                            !isNaN(
                                              Number(updatedPreparationTime)
                                            )
                                          ) {
                                            if (
                                              Number(updatedPreparationTime) >=
                                                3 &&
                                              Number(updatedPreparationTime) <=
                                                60
                                            ) {
                                              // setUpdatedPreparationTime(updatedPreparationTime);
                                              changeOrderPrepTime(
                                                prepOrder.orderId
                                              );
                                            } else {
                                              toast.error(
                                                "The timer value should be between 3 seconds to 60 seconds!!"
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        <IoIosCheckmarkCircleOutline className="w-full h-full text-ottonomyBlue" />
                                      </span>
                                      <span
                                        className="w-5 h-5 cursor-pointer"
                                        onClick={() => {
                                          setUpdatedPreparationTime("");
                                          setEditPreparationTime(null);
                                        }}
                                      >
                                        <IoIosCloseCircleOutline className="w-full h-full text-[#E96C67]" />
                                      </span>
                                    </div>
                                    <div className="flex items-center justify-end w-full h-2 px-9">
                                      <span className="text-[9px]">
                                        Max - 60
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="p-2 text-sm">
                              {locationPositionValuePriority(
                                prepOrder?.delivery
                              )}
                              {/* {prepOrder?.delivery?.locationName
                                ? prepOrder?.delivery?.lat
                                : parseFloat(prepOrder?.delivery?.lat) +
                                  ", " +
                                  parseFloat(prepOrder?.delivery?.lon)} */}
                              {/* {prepOrder?.delivery?.locationName
                              ? prepOrder?.delivery?.locationName
                              : parseFloat(
                                  prepOrder?.delivery?.positionX
                                ).toFixed(8) +
                                ", " +
                                parseFloat(
                                  prepOrder?.delivery?.positionY
                                ).toFixed(8) +
                                ", " +
                                parseFloat(
                                  prepOrder?.delivery?.positionYaw
                                ).toFixed(8)} */}
                            </td>

                            <td className="p-2">
                              <RiDeleteBin6Line
                                onClick={() => {
                                  setDeletedOrderId(prepOrder.orderId);
                                  setOpenRemoveOrderModal(true);
                                }}
                                className="w-5 h-5 cursor-pointer text-[#E96C67]"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-[rgb(255,255,255,0.7)] font-semibold flex items-center justify-center w-full h-full ">
                    {" "}
                    {fleetData?.booking?.analyticsConfig?.termForOrder
                      ? fleetData?.booking?.analyticsConfig?.termForOrder + "s"
                      : "Orders"}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}

        {/* orders booked card */}
        {hasPermissionForBookedOrders && (
          <div
            className={`grid shadow-md col-span-full md:${
              fleetData.preparationNeeded
                ? hasPermissionForSummon || hasPermissionForSummonStoreLocation
                  ? "col-span-5"
                  : "col-span-2"
                : hasPermissionForSummon || hasPermissionForSummonStoreLocation
                ? "col-span-3"
                : "col-span-5"
            } text-textColor bg-primaryColor p-3 rounded-lg `}
          >
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-3 pb-1 border-b border-b-ottonomyBlue">
                {preparedOrderData && preparedOrderData.length > 0 && (
                  <>
                    <span className="text-3xl font-semibold font-poppins text-ottonomyBlue">
                      {preparedOrderData.length}
                    </span>
                  </>
                )}
                <span className="text-lg font-semibold font-poppins">
                  {fleetData.preparationNeeded
                    ? "Orders Prepared"
                    : `${
                        fleetData?.booking?.analyticsConfig?.termForOrder
                          ? fleetData?.booking?.analyticsConfig?.termForOrder +
                            "s"
                          : "Orders"
                      } Booked`}
                </span>
                <button
                  className="p-1 text-sm flex gap-x-1 rounded self-center"
                  onClick={rescheduleOrders}
                >
                  <IoReloadCircleSharp
                    className={`h-6 w-6 ${
                      isSuggestionsLoading ? "animate-spin" : ""
                    }`}
                  />
                </button>
              </div>

              <div className="flex flex-col gap-2 h-[400px] overflow-auto ">
                {preparedOrderData && preparedOrderData.length > 0 ? (
                  <>
                    {preparedOrderData?.map((order, index) => (
                      <div
                        key={"prepared__order__no__" + index}
                        className="flex flex-col bg-[#1C1E2A] rounded-md p-2 gap-3"
                      >
                        <div className="flex justify-between flex-wrap relative">
                          <span
                            className="absolute flex top-3 right-3"
                            onClick={() => {
                              setDeletedOrderId(order.orderId);
                              setOpenRemoveOrderModal(true);
                            }}
                          >
                            <RiDeleteBin6Line className="w-5 h-5 cursor-pointer text-[#E96C67]" />
                          </span>
                          <div className="flex flex-col p-2 pb-0">
                            <span className="text-[15px] font-bold text-ottonomyBlue">
                              {order.orderId}
                            </span>
                            <span className="italic text-[rgba(255,255,255,0.5)] font-extralight text-[11px]">
                              {new Date(order.createdAt).toLocaleString(
                                "en-US",
                                {
                                  timeZone:
                                    fleetData?.map?.timeZone ??
                                    "America/New_York",
                                  timeZoneName: "short",
                                }
                              )}{" "}
                            </span>
                            <span className="text-xs font-light">
                              {locationPositionValuePriority(order?.delivery)}
                            </span>
                            {order?.hasOwnProperty("robotId") && (
                              <div className="flex items-center gap-1">
                                {/* <span className="!text-xs font-semibold text-ottonomyBlue">
                            Robot ID:{" "}
                          </span> */}
                                <span className="text-xs text-textColor">
                                  {order?.robotId}
                                </span>
                              </div>
                            )}
                            {order?.hasOwnProperty("cabinId") && (
                              <div className="flex items-center gap-1">
                                {/* <span className="text-xs font-semibold text-ottonomyBlue">
                            Compartment:{" "}
                          </span> */}
                                <span className="text-xs text-textColor">
                                  {order?.cabinId}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col items-end justify-end p-2 pb-0">
                            <div className="flex items-center gap-1 font-bold text-[#E7E703]">
                              {/* <span className="text-sm">PIN:</span> */}
                              {order?.pickPin
                                ?.split("")
                                .map((pinNumber, index) => (
                                  <span
                                    key={"pin__no__" + index}
                                    className="text-xs px-2 py-1 border-[1px] border-[#E7E703] rounded-md"
                                  >
                                    {pinNumber}
                                  </span>
                                ))}
                            </div>
                          </div>
                        </div>
                        {order?.suggestedSchedule && (
                          <div
                            style={{
                              backgroundColor:
                                groupsColor[order?.suggestedSchedule?.groupId],
                            }}
                            className="w-full h-1.5 self-center rounded"
                          />
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text-textColor font-semibold flex items-center justify-center w-full h-full">
                    {" "}
                    No{" "}
                    {fleetData?.booking?.analyticsConfig?.termForOrder
                      ? fleetData?.booking?.analyticsConfig?.termForOrder + "s"
                      : "Orders"}{" "}
                    to show
                  </div>
                )}
              </div>

              {suggestionsUpdatedTimeStamp && (
                <p className="text-xs font-light">
                  Last updated:{" "}
                  {calculateTimeDifference(suggestionsUpdatedTimeStamp)} mins
                  ago
                </p>
              )}
            </div>
          </div>
        )}

        {/* orders in transit table  */}
        <div
          className={`${
            hasPermissionForSummon ||
            hasPermissionForSummonStoreLocation ||
            hasPermissionForBookedOrders
              ? "col-span-full"
              : "col-span-5"
          } flex flex-col w-full text-textColor shadow-md bg-primaryColor p-3 rounded-lg `}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-3 pb-1 border-b border-b-ottonomyBlue">
              {orderlistData && orderlistData.length > 0 && (
                <span className="text-3xl font-semibold font-poppins text-ottonomyBlue">
                  {orderlistData.length}
                </span>
              )}
              <span className="text-lg font-semibold font-poppins">
                {fleetData?.booking?.analyticsConfig?.termForOrder
                  ? `Ongoing ${
                      fleetData?.booking?.analyticsConfig?.termForOrder
                    }${orderlistData.length > 1 ? "s" : ""}`
                  : "Orders in Transit"}
              </span>
            </div>

            <div className="flex flex-col gap-2 overflow-x-auto">
              {orderlistData && orderlistData.length !== 0 ? (
                <table>
                  <thead className="text-[rgb(255,255,255,0.7)] border-b !border-b-grey">
                    <tr className="font-semibold">
                      <td className="p-3 !text-sm">Robot Name</td>
                      <td className="p-3 !text-sm">
                        {fleetData?.booking?.analyticsConfig?.termForOrder
                          ? fleetData?.booking?.analyticsConfig?.termForOrder
                          : "Order"}{" "}
                        ID
                      </td>
                      {orderlistData.some((order) =>
                        order.hasOwnProperty("orderInfo")
                      ) && <td className="p-3 !text-sm">Order Info</td>}
                      <td className="p-3 !text-sm">Status</td>
                      <td className="p-3 !text-sm">Compartment</td>
                      {fleetData?.booking?.pickup &&
                        fleetData?.booking?.pickup.length !== 0 && (
                          <td className="p-3 !text-sm">
                            Pickup{" "}
                            {locationPositionLabelPriority(
                              orderlistData[0].pickup
                            )}
                          </td>
                        )}
                      {fleetData?.booking?.delivery &&
                        fleetData?.booking?.delivery.length !== 0 && (
                          <td className="p-3 !text-sm">
                            Delivery{" "}
                            {locationPositionLabelPriority(
                              orderlistData[0].delivery
                            )}
                          </td>
                        )}
                      <td className="p-3 !text-sm">Pickup Pin</td>
                      <td className="p-3 !text-sm">Delivery Pin</td>
                      <td className="p-3 !text-base">Time Stamp</td>
                      <td className="p-3 !text-base"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {orderlistData.map((order, index) => (
                      <TransitDetails
                        key={"transit_details_" + index}
                        orderIndex={index}
                        orderlistData={orderlistData}
                        order={order}
                        editCancellationTime={editCancellationTime}
                        // editOrderCancellationTime={editOrderCancellationTime}
                        // changeOrderCancTime={changeOrderCancTime}
                        updatedCancellationTime={updatedCancellationTime}
                        handleCancellationInputChange={
                          handleCancellationInputChange
                        }
                        setUpdatedCancellationTime={setUpdatedCancellationTime}
                        setEditCancellationTime={setEditCancellationTime}
                        setDeletedTransitOrderId={setDeletedTransitOrderId}
                        setOpenRemoveTransitOrderModal={
                          setOpenRemoveTransitOrderModal
                        }
                        dropLocationOptions={dropLocationOptions}
                        pickupLocationOptions={pickupLocationOptions}
                        confirmEditLocationModal={confirmEditLocationModal}
                        setConfirmEditLocationModal={
                          setConfirmEditLocationModal
                        }
                        confirmEditDropLocationModal={
                          confirmEditDropLocationModal
                        }
                        setConfirmEditDropLocationModal={
                          setConfirmEditDropLocationModal
                        }
                        setSelectedLocation={setSelectedLocation}
                        setSelectedOrderId={setSelectedOrderId}
                        isPickupLocationEditedToggle={
                          isPickupLocationEditedToggle
                        }
                        setIsPickupLocationEditedToggle={
                          setIsPickupLocationEditedToggle
                        }
                        isDropLocationEditedToggle={isDropLocationEditedToggle}
                        setIsDropLocationEditedToggle={
                          setIsDropLocationEditedToggle
                        }
                        fleetData={fleetData}
                        editPreparationTime={editPreparationTime}
                        setConfirmationCancelOrder={setConfirmationCancelOrder}
                        changeOrderCancTime={changeOrderCancTime}
                        setSelectedCancelOrderId={setSelectedCancelOrderId}
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-textColor font-semibold flex flex-grow items-center justify-center w-full h-full ">
                  {" "}
                  No{" "}
                  {fleetData?.booking?.analyticsConfig?.termForOrder
                    ? fleetData?.booking?.analyticsConfig?.termForOrder + "s"
                    : "Orders"}{" "}
                  to show
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col w-full gap-2 py-4 mt-2 overflow-auto"> */}
      <RemoveOrderModal
        openRemoveOrderModal={openRemoveOrderModal}
        setOpenRemoveOrderModal={setOpenRemoveOrderModal}
        deletedOrderId={deletedOrderId}
        setDeletedOrderId={setDeletedOrderId}
        deleteOrder={deleteOrder}
      />

      <RemoveTransitOrderModal
        openRemoveTransitOrder={openRemoveTransitOrder}
        setOpenRemoveTransitOrderModal={setOpenRemoveTransitOrderModal}
        deletedTransitOrderId={deletedTransitOrderId}
        setDeletedTransitOrderId={setDeletedTransitOrderId}
        deleteOrderTransit={deleteOrderTransit}
      />

      <ChangeLocationConfirmModal
        confirmEditLocationModal={confirmEditLocationModal}
        handleCloseConfirmEditLocationModal={
          handleCloseConfirmEditLocationModal
        }
        selectedLocation={selectedLocation}
        handleChangeLocation={handleChangeLocation}
        selectedOrderId={selectedOrderId}
        isPickupLocationEditedToggle={isPickupLocationEditedToggle}
        setIsPickupLocationEditedToggle={setIsPickupLocationEditedToggle}
      />

      <ChangeDropLocationConfirmModal
        confirmEditDropLocationModal={confirmEditDropLocationModal}
        handleCloseConfirmEditDeliveryLocationModal={
          handleCloseConfirmEditDeliveryLocationModal
        }
        selectedDropLocation={selectedLocation}
        handleChangeLocation={handleChangeLocation}
        selectedOrderId={selectedOrderId}
        isDropLocationEditedToggle={isDropLocationEditedToggle}
        setIsDropLocationEditedToggle={setIsDropLocationEditedToggle}
      />

      <CancelOrderConfirmModal
        confirmationCancelOrder={confirmationCancelOrder}
        setConfirmationCancelOrder={setConfirmationCancelOrder}
        selectedCancelOrderId={selectedCancelOrderId}
        setSelectedCancelOrderId={setSelectedCancelOrderId}
        changeOrderCancTime={changeOrderCancTime}
      />
    </>
  );
}

export default OrderListNew;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import * as yup from "yup";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { addMap, askimageurl, editMap, imageupload } from "../API";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { toast, ToastContainer } from "react-toastify";
import CreateIcon from "@mui/icons-material/Create";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "var(--primaryColor)",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function EditMap(props) {
  const toastmsg = (msg) => toast(msg);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = (reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };
  const [imagepath, setimagepath] = React.useState();
  const [savebutton, setsavebutton] = React.useState(false);
  const fleetId = localStorage.getItem("fleetId");
  const token = localStorage.getItem("token");
  const validationSchema = yup.object({
    mapName: yup.string(),
    mapWidth: yup.string(),
    mapHeight: yup.string(),
    mapOriginX: yup.string(),
    mapOriginY: yup.string(),
    mapScale: yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      mapName: props.mapData.mapName,
      mapWidth: props.mapData.mapWidth,
      mapHeight: props.mapData.mapHeight,
      mapOriginX: props.mapData.mapOrigionX,
      mapOriginY: props.mapData.mapOrigionY,
      mapScale: props.mapData.mapScale,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      editMap(values, fleetId, token, imagepath, props.mapId)
        .then((res) => {
          //  console.log('Edit Map APi Response', res)
          toastmsg(res.data.message);
          props.changerefresh();
          handleClose();
        })
        .catch((err) => {
          console.log("Add Map APi error", err);
        });
    },
  });
  const handleImageUrl = (e) => {
    var bodyFormData = new FormData();

    bodyFormData.append("file", e.target.files[0]);
    imageupload(token, bodyFormData)
      .then((res) => {
        console.log("Image Upload response", res.data.path);
        //  image=res.data.path
        setimagepath(res.data.path);
        setsavebutton(true);
      })
      .catch((err) => {
        console.log("Error in Image Upload", err);
      });
    // formik.setFieldValue('imageUrl', imagepath);
  };

  return (
    <>
      <button className="EditMap_button" onClick={handleOpen}>
        <CreateOutlinedIcon sx={{ fontSize: "20px" }} />
        Edit Map
      </button>
      <Modal
        open={open}
        // BackdropProps={false}
        onHide={handleClose}
        // onClose={handleClose}
        backdrop="static"
        keyboard="false"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="AddMap_modal_wrapper">
          <div className="AddMap_Modal_content_wrapper">
            <h3> Add Map</h3>
            <form onSubmit={formik.handleSubmit}>
              <div
                className="AddFleet_form_field_wrapper"
                id="AddMap_Modal_textfield_wrapper"
              >
                <div className="AddFleet_form_field_wrapper_inner_div">
                  <label> Map Name </label>
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={
                      {
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                        //     </InputAdornment>
                        //   ),
                      }
                    }
                    value={formik.values.mapName}
                    name="mapName"
                    onChange={formik.handleChange}
                    placeholder="Map Name"
                    error={
                      formik.touched.mapName && Boolean(formik.errors.mapName)
                    }
                    helperText={formik.touched.mapName && formik.errors.mapName}
                    style={{ margin: "15px" }}
                  />
                </div>
                <div className="AddFleet_form_field_wrapper_inner_div">
                  <label> Map Width </label>
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={
                      {
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                        //     </InputAdornment>
                        //   ),
                      }
                    }
                    value={formik.values.mapWidth}
                    name="mapWidth"
                    onChange={formik.handleChange}
                    placeholder="Map Width"
                    error={
                      formik.touched.mapWidth && Boolean(formik.errors.mapWidth)
                    }
                    helperText={
                      formik.touched.mapWidth && formik.errors.mapWidth
                    }
                    style={{ margin: "15px" }}
                  />
                </div>
              </div>
              <div
                className="AddFleet_form_field_wrapper"
                id="AddMap_Modal_textfield_wrapper"
              >
                <div className="AddFleet_form_field_wrapper_inner_div">
                  <label> Map Height </label>
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={
                      {
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                        //     </InputAdornment>
                        //   ),
                      }
                    }
                    value={formik.values.mapHeight}
                    name="mapHeight"
                    onChange={formik.handleChange}
                    placeholder="Map Height"
                    error={
                      formik.touched.mapHeight &&
                      Boolean(formik.errors.mapHeight)
                    }
                    helperText={
                      formik.touched.mapHeight && formik.errors.mapHeight
                    }
                    style={{ margin: "15px" }}
                  />
                </div>
                <div className="AddFleet_form_field_wrapper_inner_div">
                  <label> Map OriginX </label>
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={
                      {
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                        //     </InputAdornment>
                        //   ),
                      }
                    }
                    value={formik.values.mapOriginX}
                    name="mapOriginX"
                    onChange={formik.handleChange}
                    placeholder="Map OriginX"
                    error={
                      formik.touched.mapOriginX &&
                      Boolean(formik.errors.mapOriginX)
                    }
                    helperText={
                      formik.touched.mapOriginX && formik.errors.mapOriginX
                    }
                    style={{ margin: "15px" }}
                  />
                </div>
              </div>
              <div
                className="AddFleet_form_field_wrapper"
                id="AddMap_Modal_textfield_wrapper"
              >
                <div className="AddFleet_form_field_wrapper_inner_div">
                  <label> Map Scale </label>
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={
                      {
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                        //     </InputAdornment>
                        //   ),
                      }
                    }
                    value={formik.values.mapScale}
                    name="mapScale"
                    onChange={formik.handleChange}
                    placeholder="Map Scale"
                    error={
                      formik.touched.mapScale && Boolean(formik.errors.mapScale)
                    }
                    helperText={
                      formik.touched.mapScale && formik.errors.mapScale
                    }
                    style={{ margin: "15px" }}
                  />
                </div>
                <div className="AddFleet_form_field_wrapper_inner_div">
                  <label> Map Origin Y</label>
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={
                      {
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //     <PersonIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                        //     </InputAdornment>
                        //   ),
                      }
                    }
                    value={formik.values.mapOriginY}
                    name="mapOriginY"
                    onChange={formik.handleChange}
                    placeholder="Map OriginY"
                    error={
                      formik.touched.mapOriginY &&
                      Boolean(formik.errors.mapOriginY)
                    }
                    helperText={
                      formik.touched.mapOriginY && formik.errors.mapOriginY
                    }
                    style={{ margin: "15px" }}
                  />
                </div>
              </div>
              <div
                className="AddFleet_form_field_wrapper"
                id="AddMap_Modal_textfield_wrapper"
              >
                {/* <div className="AddFleet_form_field_wrapper_inner_div" id="AddMap_choose_image">
                <label> Floor Plan Image</label> 
                <label for="files1" class="btn" id="AddMap_modal_upload_image">Upload Image</label>
                <input id="files1" type="file" accept="image/*" name="floorPlanImage" onChange={formik.handleChange} error={
                        formik.touched.floorPlanImage &&
                        Boolean(formik.errors.floorPlanImage)
                    }
                    helperText={
                        formik.touched.floorPlanImage && formik.errors.floorPlanImage
                    }
                    style={{margin:'15px',marginBottom: '5px', padding:'10px'}}/> 
              {formik.touched.floorPlanImage  && formik.errors.floorPlanImage? <p className="AddMap_text_error"> {formik.errors.floorPlanImage}</p>:null} 
            </div> */}
                {/* <div className="AddFleet_form_field_wrapper_inner_div" id="AddMap_choose_image">
              <label> Base Map Image</label>
              <label for="files2" class="btn" id="AddMap_modal_upload_image">Upload Image</label>
             <input id="files2" type="file" accept="image/*" name="baseMapImage" onChange={formik.handleChange} error={
                        formik.touched.baseMapImage &&
                        Boolean(formik.errors.baseMapImage)
                    }
                    helperText={
                        formik.touched.baseMapImage && formik.errors.baseMapImage
                    }
                    style={{margin:'15px',marginBottom: '5px', padding:'10px'}}/>    
                {formik.touched.baseMapImage && formik.errors.baseMapImage? <p className="AddMap_text_error"> {formik.errors.baseMapImage}</p>:null}          
           </div>     */}
                <div
                  className="AddFleet_form_field_wrapper_inner_div"
                  id="AddMap_choose_image"
                >
                  <label> Image Url</label>
                  <label
                    for="files2"
                    class="btn"
                    id="AddMap_modal_upload_image"
                  >
                    Upload Image
                  </label>
                  <input
                    id="files2"
                    type="file"
                    accept="image/png,image/gif,image/jpeg"
                    name="imageUrl"
                    onChange={(e) => handleImageUrl(e)}
                    style={{
                      margin: "15px",
                      marginBottom: "5px",
                      padding: "10px",
                    }}
                  />
                  {/* {formik.touched.imageUrl && formik.errors.imageUrl? <p className="AddMap_text_error"> {formik.errors.imageUrl}</p>:null}           */}
                </div>
              </div>
              {/* <div className="AddFleet_form_field_wrapper" id="AddMap_Modal_textfield_wrapper">
            <div className="AddFleet_form_field_wrapper_inner_div" id="AddMap_choose_image"> 
                <label> Nogo Zone Image</label>
                <label for="files3" class="btn" id="AddMap_modal_upload_image">Upload Image</label>
            <input id="files3" type="file" accept="image/*" name="NogoZoneImage" onChange={formik.handleChange} error={
                        formik.touched.NogoZoneImage &&
                        Boolean(formik.errors.NogoZoneImage)
                    }
                    helperText={
                        formik.touched.NogoZoneImage && formik.errors.NogoZoneImage
                    }
                    style={{margin:'15px',marginBottom: '5px', padding:'10px'}}/>
                 {formik.touched.NogoZoneImage && formik.errors.NogoZoneImage ? <p className="AddMap_text_error"> {formik.errors.NogoZoneImage }</p>:null}    
            </div>
             <div className="AddFleet_form_field_wrapper_inner_div" id="AddMap_choose_image"> 
                <label> Annoted Image</label>
                <label for="files4" class="btn" id="AddMap_modal_upload_image">Upload Image</label>
               <input id="files4" type="file" accept="image/*" name="AnnotedImage" onChange={formik.handleChange} error={
                        formik.touched.AnnotedImage &&
                        Boolean(formik.errors.AnnotedImage)
                    }
                    helperText={
                        formik.touched.AnnotedImage && formik.errors.AnnotedImage
                    }
                    style={{margin:'15px',marginBottom: '5px', padding:'10px'}}/>  
                          
             {formik.touched.AnnotedImage && formik.errors.AnnotedImage ? <p className="AddMap_text_error">{formik.errors.AnnotedImage}</p>:null}
             </div>  
        </div>  */}
              <div className="Add_map_cancel_save_button">
                <button onClick={handleClose}> Cancel </button>
                <button type="submit"> Save</button>
                {/* {savebutton ? <button type="submit"> Save</button> : <button disabled> Save </button>}  */}
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default React.memo(EditMap);

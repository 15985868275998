import { Box, Button, Modal } from "@mui/material";
import React from "react";

const ChangeLocationConfirmModal = ({
  confirmEditLocationModal,
  handleCloseConfirmEditLocationModal,
  selectedLocation,
  handleChangeLocation,
  selectedOrderId,
  isLocationEditedToggle,
  setIsLocationEditedToggle,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 430,
    bgcolor: "var(--primaryColor)",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };
  return (
    <Modal
      open={confirmEditLocationModal}
      // BackdropProps={false}
      onHide={handleCloseConfirmEditLocationModal}
      // onClose={handleClose}
      backdrop="static"
      keyboard="false"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="Cancel-order-Modal-Mainwrapper">
          {/* <img src="/assets/images/cancel-order.png"/> */}
          <p> Are you sure you want to change the pickup location ?</p>
          <div className="Modal-button-Main-wrapper">
            <Button
              className="Modal-button-Main-btn yes-red-active"
              onClick={() => {
                handleChangeLocation(selectedOrderId, selectedLocation, "pickup");
                handleCloseConfirmEditLocationModal();
                setIsLocationEditedToggle(false);
              }}
            >
              {" "}
              Yes
            </Button>
            <Button
              className="Modal-button-Main-btn color-white"
              onClick={() => handleCloseConfirmEditLocationModal()}
            >
              {" "}
              No
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ChangeLocationConfirmModal;

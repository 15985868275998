import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import LockIcon from "@mui/icons-material/Lock";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useTheme } from "../context/ThemeContext";
import { Autocomplete, TextField } from "@mui/material";
import { FormControl } from "react-bootstrap";
import { themes } from "../Data/ThemeData";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "var(--primaryColor)",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function SettingsModal({ isOpen, onClose }) {
  const { switchTheme, themeMode } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(themeMode);

  const handleModalClose = () => {
    onClose();
  };

  console.log(themeMode, "modal theme");

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      backdrop="static"
      keyboard="false"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="edit-password-modal-main-wrapper">
          <h3 className="text-ottonomyBlue font-bold text-center">Settings</h3>
          <div className="flex flex-col gap-y-2 text-textColor">
            <div className="flex flex-col gap-y-2">
              <label htmlFor="currentPassword" className="edit-password-label">
                Theme
              </label>
              <div className="relative">
                <div className="bg-bodycolor text-textColor">
                  <Autocomplete
                    sx={{
                      "& .MuiInputBase-root": {
                        color: "var(--textColor)", // Changes input text color
                        textTransform: "capitalize", // Capitalize options in the dropdown
                      },
                      "& .MuiInputBase-input": {
                        textTransform: "capitalize", // Capitalize options in the dropdown
                      },
                      "& .MuiInputLabel-root": {
                        color: "var(--textColor)", // Changes label color
                        // textTransform: "capitalize", // Capitalize options in the dropdown
                      },

                      "& MuiInput-input": {
                        // textTransform: "capitalize", // Capitalize options in the dropdown
                      },

                    }}
                    options={themes}
                    value={themeMode}
                    onChange={(_, selectedOption) => {
                      switchTheme(selectedOption);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // inputProps={{color: 'var(--textColor)'}}
                        className=" !m-0 !p-[5px_5px_5px_10px] text-sm"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="edit-password-modal-buttons flex justify-between pt-3">
            <button
              className="bg-ottonomyBlue text-white p-2 rounded font-bold hover:bg-[#1c1e2b]"
              onClick={handleModalClose}
            >
              Close
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default React.memo(SettingsModal);

import { Box, Button, Modal } from "@mui/material";
import React from "react";

const ChangeDropLocationConfirmModal = ({
  confirmEditDropLocationModal,
  handleCloseConfirmEditDeliveryLocationModal,
  selectedDropLocation,
  handleChangeLocation,
  selectedOrderId,
  isDropLocationEditedToggle,
  setIsDropLocationEditedToggle,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 430,
    bgcolor: "var(--primaryColor)",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };
  return (
    <Modal
      open={confirmEditDropLocationModal}
      // BackdropProps={false}
      onHide={handleCloseConfirmEditDeliveryLocationModal}
      // onClose={handleClose}
      backdrop="static"
      keyboard="false"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="Cancel-order-Modal-Mainwrapper">
          {/* <img src="/assets/images/cancel-order.png"/> */}
          <p> Are you sure you want to change the drop off location ?</p>
          <div className="Modal-button-Main-wrapper">
            <Button
              className="Modal-button-Main-btn yes-red-active"
              onClick={() => {
                handleChangeLocation(selectedOrderId, selectedDropLocation, "drop");
                handleCloseConfirmEditDeliveryLocationModal();
                setIsDropLocationEditedToggle(false);
              }}
            >
              {" "}
              Yes
            </Button>
            <Button
              className="Modal-button-Main-btn color-white"
              onClick={() => handleCloseConfirmEditDeliveryLocationModal()}
            >
              {" "}
              No
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ChangeDropLocationConfirmModal;

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Loader from "../subcomponents/Loader";

ChartJS.register(ArcElement, Tooltip, Legend);

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b) => b.lines);
    const div = document.createElement("div");

    div.className = "flex flex-col items-center justify-center";

    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      let numberData = body[i].split(":");

      const span1 = document.createElement("span");
      span1.style.color = colors.backgroundColor;
      span1.className = "text-xl font-semibold leading-5";

      const span2 = document.createElement("span");
      span2.style.color = "rgba(2, 2, 2, 0.5)";
      span2.className = "text-[11px]";

      const text1 = document.createTextNode(numberData[1]);
      const text2 = document.createTextNode(tooltip.title[0]);

      span1.appendChild(text1);
      div.appendChild(span1);

      span2.appendChild(text2);
      div.appendChild(span2);
    });

    const tableRoot = tooltipEl.querySelector("table");

    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot.appendChild(div);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
  tooltipEl.style.background = "white";
};

const CabinPolarChart = ({ analyticsData }) => {

  let cabinColors = ["#ECAE3F", "#EA515A", "#7E57C2", "#61B7D4", "#CCF381", "#E2D1F9", "#990011", "#EE4E34", "#CBD18F", "#3A6B35"];

  const data = {
    labels: analyticsData?.cabinCount?.map((cabin) => {
      return cabin.cabinName;
    }),
    datasets: [
      {
        label: " ",
        data: analyticsData?.cabinCount?.map((cabin) => {
          return cabin.uniqueCount;
        }),
        backgroundColor: cabinColors,
        // backgroundColor: cabinColors,
        borderColor: cabinColors,
        borderAlign: "center",
      },
    ],
  };

  // const options = {
  //   //   elements: {
  //   //   },
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //     tooltip: {
  //       enabled: false,
  //       position: "nearest",
  //       external: externalTooltipHandler,
  //     },
  //   },
  //   responsive: true,
  //   //   maintainAspectRatio: false,
  //   scales: {
  //     r: {
  //       grid: {
  //         display: true,
  //         color: "#404554",
  //       },
  //       ticks: {
  //         display: false,
  //       },
  //     },
  //   },
  // };

  const options = {
    elements: {
      arc: {
        roundedCornersFor: 0,
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: externalTooltipHandler,
      },
    },
    responsive: true,
  };

  return (
    <>
      {analyticsData && Object.keys(analyticsData).length === 0 && (
        <div className="flex items-center justify-center w-full">
          <Loader />
        </div>
      )}
      {analyticsData &&
        analyticsData.hasOwnProperty("cabinCount") &&
        analyticsData.cabinCount.length === 0 && (
          <span className="flex items-center justify-center w-full text-lg font-semibold text-textColor">
            No data to show
          </span>
        )}

      {analyticsData &&
        analyticsData.hasOwnProperty("cabinCount") &&
        analyticsData.cabinCount.length !== 0 && (
          <>
            <div className="w-[50%] h-max">
              {/* <PolarArea data={data} options={options} /> */}
              <Doughnut data={data} options={options} className="relative" />
            </div>
            <div className="flex flex-col gap-3 w-[49%]">
              <div className="flex flex-col gap-3">
                <div className="flex flex-wrap items-center justify-between p-2 gap-y-3">
                  {analyticsData?.cabinCount?.map((cabin, index) => {
                    return (
                      <div
                        className="flex flex-col"
                        key={"cabin__number__" + index}
                      >
                        <span className="text-[#BEC4DE] text-xl font-semibold">
                          {cabin.uniqueCount}
                        </span>
                        <div className="flex flex-col gap-1">
                          <span
                            className={`h-1 w-4 bg-[${cabinColors[index]}]`}
                          ></span>{" "}
                          <span className="text-sm font-light text-textColor">
                            {cabin.cabinName}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                  {/* <div className="flex flex-col">
              <span className="text-[#BEC4DE] text-xl font-semibold">43</span>
              <div className="flex flex-col gap-1">
                <span className="h-1 w-4 bg-ottonomyPink"></span>
                <span className="text-sm font-light text-textColor">
                  cabin 2
                </span>
              </div>
            </div> */}
                </div>
                {/* <div className="flex items-center justify-between p-2">
            <div className="flex flex-col">
              <span className="text-[#BEC4DE] text-xl font-semibold">45</span>
              <div className="flex flex-col gap-1">
                <span className="h-1 w-4 bg-[#7E57C2]"></span>{" "}
                <span className="text-sm font-light text-textColor">
                  cabin 3
                </span>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-[#BEC4DE] text-xl font-semibold">67</span>
              <div className="flex flex-col gap-1">
                <span className="h-1 w-4 bg-[#61B7D4]"></span>
                <span className="text-sm font-light text-textColor">
                  cabin 4
                </span>
              </div>
            </div>
          </div> */}
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default CabinPolarChart;

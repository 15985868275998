import React from "react";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import DashboardSideBar from "./DashboardSideBar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DashboardBottomBar from "../components/DashboardBottomBar";
import Layout from "../components/Layout";

function AddRobot(props) {
  const isopen = useSelector((state) => state.dashboardopenReducer);
  const { sidebarcollapse } = useSelector((state) => state.login);

  const validationSchema = yup.object({
    robotName: yup.string().required("Robot Name is required"),
    robotId: yup.string().required("RobotID is Required"),
    location: yup.string().required("Location is required"),
    latitude: yup
      .number("Latitude must be number or float")
      .required("Latitude is required"),
    longitude: yup
      .number("Longitude must be number or float")
      .required("Longitude is required"),
    endpoint: yup.string().required("End Point is Required"),
    fleet: yup.string().required("Please Select Fleet"),
  });

  const formik = useFormik({
    initialValues: {
      robotName: "",
      robotId: "",
      location: "",
      latitude: "",
      longitude: "",
      endpoint: "",
      fleet: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  return (
    <>
      <Layout dashboardopen={props.dashboardopen} activeTab={props.activeTab} setActiveTab={props.setActiveTab}>
      <div className="Fleet_page_Whole_content_wrapper_main">
          {/* <div
            className={`hidden midLg:block ${
              sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"
            }`}
          >
            <DashboardSideBar
              dashboardopen={props.dashboardopen}
              active="robotpage"
            />
            <DashboardBottomBar active="robotpage" />
          </div> */}
          <div
            className={
              isopen.userlogindata
                ? "Dashboard_page_right_side_fleetpage margin_left_close"
                : "Dashboard_page_right_side_fleetpage margin_left"
            }
          >
            <h4 className="Dashboard_page_Robot_Card_heading">
              Robots <ArrowForwardIosIcon /> Add Robot{" "}
            </h4>
            <div className="AddFleet_page_content">
              <form onSubmit={formik.handleSubmit}>
                <div className="AddFleet_form_field_wrapper">
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    value={formik.values.robotName}
                    name="robotName"
                    onChange={formik.handleChange}
                    placeholder="Robot Name"
                    error={
                      formik.touched.robotName && Boolean(formik.errors.robotName)
                    }
                    helperText={
                      formik.touched.robotName && formik.errors.robotName
                    }
                    style={{ margin: "15px" }}
                  />
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    value={formik.values.robotId}
                    name="robotId"
                    onChange={formik.handleChange}
                    placeholder="Robot Id"
                    error={
                      formik.touched.robotId && Boolean(formik.errors.robotId)
                    }
                    helperText={formik.touched.robotId && formik.errors.robotId}
                    style={{ margin: "15px" }}
                  />
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    value={formik.values.location}
                    name="location"
                    onChange={formik.handleChange}
                    placeholder="Location"
                    error={
                      formik.touched.location && Boolean(formik.errors.location)
                    }
                    helperText={formik.touched.location && formik.errors.location}
                    style={{ margin: "15px" }}
                  />
                </div>
                <div className="AddFleet_form_field_wrapper">
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    value={formik.values.latitude}
                    name="latitude"
                    onChange={formik.handleChange}
                    placeholder="Latitude"
                    error={
                      formik.touched.latitude && Boolean(formik.errors.latitude)
                    }
                    helperText={formik.touched.latitude && formik.errors.latitude}
                    style={{ margin: "15px" }}
                  />
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    value={formik.values.longitude}
                    name="longitude"
                    onChange={formik.handleChange}
                    placeholder="Longitude"
                    error={
                      formik.touched.longitude && Boolean(formik.errors.longitude)
                    }
                    helperText={
                      formik.touched.longitude && formik.errors.longitude
                    }
                    style={{ margin: "15px" }}
                  />
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    value={formik.values.endpoint}
                    name="endpoint"
                    onChange={formik.handleChange}
                    placeholder="End Point"
                    error={
                      formik.touched.endpoint && Boolean(formik.errors.endpoint)
                    }
                    helperText={formik.touched.endpoint && formik.errors.endpoint}
                    style={{ margin: "15px" }}
                  />
                </div>
                <div className="AddFleet_form_field_wrapper_two">
                  <FormControl
                    fullWidth
                    style={{ margin: "15px", marginTop: "0px" }}
                    variant="standard"
                  >
                    <InputLabel id="demo-simple-select-label">
                      Select Fleet
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select_two"
                      value={formik.values.fleet}
                      name="fleet"
                      label="Select fleet"
                      onChange={formik.handleChange}
                      error={formik.touched.fleet && Boolean(formik.errors.fleet)}
                      helperText={formik.touched.fleet && formik.errors.fleet}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                    {formik.touched.fleet && formik.errors.fleet ? (
                      <p className="AddMap_text_error_two">Please Select Fleet</p>
                    ) : null}
                  </FormControl>
                </div>
                <div className="AddFleet_page_save_button_wrapper">
                  <button type="submit"> Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </Layout>
    </>
  );
}

export default AddRobot;

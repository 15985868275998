import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { destroySessionUser, logout } from "../redux/actions/user";
import { destroySessionFleet } from "../redux/actions/fleet";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "var(--primaryColor)",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function LogoutModal(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };
  React.useEffect(() =>{
    if(props.onlyModal){
      handleOpen(true)
    }

  },[props.onlyModal])

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const handlelogoutbtn = () => {
    // localStorage.removeItem("useremail");
    // localStorage.removeItem("password");
    // localStorage.removeItem("token");
    // localStorage.removeItem("fleetID");
    // localStorage.removeItem("Isloggedin", "LoginFalse");
    // localStorage.removeItem("mapid");
    // localStorage.removeItem("Distance");
    // localStorage.removeItem("robotId");
    // localStorage.removeItem("role");
    // localStorage.removeItem("firstname");
    // localStorage.removeItem("lastname");
    // localStorage.removeItem("fleetData");

    // sessionStorage.removeItem("useremail", null);
    // sessionStorage.removeItem("password");
    // sessionStorage.removeItem("token");
    // sessionStorage.removeItem("fleetID");
    // sessionStorage.setItem("Isloggedin", "LoginFalse");
    // sessionStorage.removeItem("mapid");
    // sessionStorage.removeItem("Distance");
    // sessionStorage.removeItem("robotId");
    // sessionStorage.removeItem("kmlurl");
    // sessionStorage.removeItem("role");
    // sessionStorage.removeItem("fleetData")

    localStorage.clear();
    sessionStorage.clear();
    dispatch(destroySessionUser());
    dispatch(destroySessionFleet());
    navigate("/");
    toast.success("Logged out successfully!");
  };
  return (
    <>
      {props.onlyModal ? (
        <></>
      ) : props.header ? (
        <Button
          className="btn-dashboard-red-color cancel-order"
          onClick={handleOpen}
          style={{
            color: "white",
            padding: "0px",
            textTransform: "capitalize",
            fontFamily: "Poppins",
            fontSize: "15px",
            padding: "",
          }}
        >
          {props.dashboard ? (
            <PowerSettingsNewIcon className="Heade_Power_button" />
          ) : (
            <PowerSettingsNewIcon className="Heade_Power_button" />
          )}
        </Button>
      ) : (
        <Button
          className="cancel-order logout-button-footer robot_logoutbutton"
          onClick={handleOpen}
          style={{
            color: "white",
            padding: "0px",
            textTransform: "capitalize",
            fontFamily: "Poppins",
            fontSize: "15px",
            padding: "",
          }}
        >
          {props.dashboard ? (
            <p className="w-full text-xs font-normal text-ottonomyBlue hover:text-textColor">
              Logout
            </p>
          ) : (
            <PowerSettingsNewIcon className="Heade_Power_button" />
          )}
        </Button>
      )}

      <Modal
        open={open}
        // BackdropProps={false}
        onHide={handleClose}
        // onClose={handleClose}
        backdrop="static"
        keyboard="false"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Cancel-order-Modal-Mainwrapper">
            {/* <img src="/assets/images/cancel-order.png"/> */}
            <p> Are you sure want to logout ?</p>
            <div className="Modal-button-Main-wrapper">
              <Button
                className="Modal-button-Main-btn yes-red-active"
                onClick={() => {
                  handleClose();
                  handlelogoutbtn();
                }}
                sx={{color: 'white !important'}}
              >
                {" "}
                Yes
              </Button>
              <Button
                className="Modal-button-Main-btn"
                onClick={handleClose}
                sx={{
                  color: 'var(--textColor) !important',
                  border: 'var(--textColor) 1px solid !important',
                  outlineColor: 'black !important'
                }}
              >
                {" "}
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default React.memo(LogoutModal);

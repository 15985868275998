import React, { useState } from "react";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import DashboardSideBar from "./DashboardSideBar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputLabel, TextField } from "@mui/material";
import { addfleet, addorder, addUser, orderConfirm } from "../API";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Layout from "../components/Layout";

function AddOrder(props) {
  const addusererror = (msg) => toast(msg);
  const addusersuccess = (msg) => toast(msg);
  const [bookordermsg, setbookordermsg] = useState();
  const [isconfim, setisConfirm] = useState(false);
  const { sidebarcollapse } = useSelector((state) => state.login);

  const navigate = useNavigate();
  const isopen = useSelector((state) => state.dashboardopenReducer);
  const token = localStorage.getItem("token");
  const validationSchema = yup.object({
    orderId: yup.string().required("Order Id is required"),
    pickupLocation: yup
      .string()
      .required("Please Select any Pick Up Location "),
    dropoffLocation: yup
      .string()
      .required("Please select any Drop off Location"),
    QRcodepickup: yup
      .string("")
      .required("QR Code for Pickup location is required"),
    QRCodedropoff: yup
      .string("")
      .required("QR Code for DropOff location is required"),
    OrderStatus: yup.string(""),
  });
  const toastmsg = (msg) => toast(msg);
  const formik = useFormik({
    initialValues: {
      orderId: "",
      pickupLocation: "",
      dropoffLocation: "",
      QRcodepickup: "",
      QRCodedropoff: "",
      OrderStatus: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      addorder(values, token)
        .then((res) => {
          // toastmsg(res.data.message)
          setbookordermsg(res.data.message);
          if (res.data.message === "order booked") {
            setisConfirm(true);
          } else {
            setisConfirm(false);
          }
        })
        .catch((err) => {
          toastmsg(err.data.message);
        });
    },
  });
  const handleconfirmorder = () => {
    orderConfirm(formik.values.orderId, token)
      .then((res) => {
        toastmsg(res.data.message);
        if (res.status === 200 || res.status === "200") {
          setTimeout(() => {
            navigate("/orderlist");
          }, 2000);
        }
      })
      .catch((err) => {
        toastmsg(err.data.message);
      });
  };
  return (
    <>
      {/* <Header /> */}
      <Layout dashboardopen={props.dashboardopen} activeTab={props.activeTab} setActiveTab={props.setActiveTab}>
        <div className="Fleet_page_Whole_content_wrapper_main">
          {/* <div
            className={`hidden midLg:block ${
              sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"
            }`}
          >
            <DashboardSideBar
              dashboardopen={props.dashboardopen}
              active="fleetpage"
            />
          </div> */}
          <div
            className={
              isopen.userlogindata
                ? "Dashboard_page_right_side_fleetpage margin_left_close"
                : "Dashboard_page_right_side_fleetpage margin_left"
            }
          >
            <h4 className="Dashboard_page_Robot_Card_heading"> Add New Order </h4>
            <div className="AddFleet_page_content">
              <form onSubmit={formik.handleSubmit} style={{ width: "80%" }}>
                <div className="AddFleet_form_field_wrapper">
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    value={formik.values.orderId}
                    name="orderId"
                    onChange={formik.handleChange}
                    placeholder="OrderId"
                    error={
                      formik.touched.orderId && Boolean(formik.errors.orderId)
                    }
                    helperText={formik.touched.orderId && formik.errors.orderId}
                    style={{ margin: "15px" }}
                  />
                  <FormControl fullWidth style={{ margin: "15px" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select_orderList"
                      value={formik.values.pickupLocation}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      name="pickupLocation"
                      onChange={(event, newvalue) => {
                        formik.setFieldValue(
                          "pickupLocation",
                          event.target.value
                        );
                      }}
                      error={
                        formik.touched.pickupLocation &&
                        Boolean(formik.errors.pickupLocation)
                      }
                      helperText={
                        formik.touched.pickupLocation &&
                        formik.errors.pickupLocation
                      }
                    >
                      <MenuItem value="">
                        {" "}
                        <em style={{ color: "", opacity: "" }}></em> Select Pickup
                        Location
                      </MenuItem>
                      <MenuItem value="Zaragoza" defaultValue={true}>
                        Zaragoza
                      </MenuItem>
                      <MenuItem value="Verizon LA">Verizon LA</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="AddFleet_form_field_wrapper">
                  <FormControl fullWidth style={{ margin: "15px" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select_orderList"
                      value={formik.values.dropoffLocation}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      name="dropoffLocation"
                      onChange={(event, newvalue) => {
                        formik.setFieldValue(
                          "dropoffLocation",
                          event.target.value
                        );
                      }}
                      error={
                        formik.touched.dropoffLocation &&
                        Boolean(formik.errors.dropoffLocation)
                      }
                      helperText={
                        formik.touched.dropoffLocation &&
                        formik.errors.dropoffLocation
                      }
                    >
                      <MenuItem value="">
                        {" "}
                        <em style={{ color: "", opacity: "" }}></em> Select
                        DropOff Location
                      </MenuItem>
                      <MenuItem value="Zaragoza" defaultValue={true}>
                        Zaragoza
                      </MenuItem>
                      <MenuItem value="Verizon LA">Verizon LA</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    value={formik.values.QRcodepickup}
                    name="QRcodepickup"
                    onChange={formik.handleChange}
                    placeholder="QRcode pick up"
                    error={
                      formik.touched.QRcodepickup &&
                      Boolean(formik.errors.QRcodepickup)
                    }
                    helperText={
                      formik.touched.QRcodepickup && formik.errors.QRcodepickup
                    }
                    style={{ margin: "15px" }}
                  />
                </div>
                <div className="AddFleet_form_field_wrapper">
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    value={formik.values.QRCodedropoff}
                    name="QRCodedropoff"
                    onChange={formik.handleChange}
                    placeholder="QRcode pick up"
                    error={
                      formik.touched.QRCodedropoff &&
                      Boolean(formik.errors.QRCodedropoff)
                    }
                    helperText={
                      formik.touched.QRCodedropoff && formik.errors.QRCodedropoff
                    }
                    style={{ margin: "15px" }}
                  />
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    value={formik.values.OrderStatus}
                    name="OrderStatus"
                    onChange={formik.handleChange}
                    placeholder="Order Status"
                    error={
                      formik.touched.OrderStatus &&
                      Boolean(formik.errors.OrderStatus)
                    }
                    helperText={
                      formik.touched.OrderStatus && formik.errors.OrderStatus
                    }
                    style={{ margin: "15px" }}
                  />
                </div>
                <div className="AddFleet_page_save_button_wrapper">
                  {bookordermsg ? (
                    <div className="AddOrder_text_response_wrapper">
                      <p> {bookordermsg}</p>
                    </div>
                  ) : null}

                  {isconfim ? (
                    <button onClick={handleconfirmorder}> Confirm Order</button>
                  ) : (
                    <button type="submit"> Add Order</button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AddOrder;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosConfig";
import Modal from "../Reusable/Modal";
import Dropdown from "../Reusable/Dropdown";
import Switch from '@mui/material/Switch';

import { useDispatch } from "react-redux";
import { robotActions } from "../../redux/slice/robotSlice";

const EditRobot = ({
  isOpen,
  onClose,
  robot,
  fleet,
}) => {
  const [initialValues, setInitialValues] = useState(null);
  const [modalValues, setModalValues] = useState(null);
  const [updateValues, setUpdateValues] = useState([]);
  const [isChangeMade, setIsChangeMade] = useState(false);
  const [fleetOptions, setFleetOptions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (fleet?.length) {
      const options = fleet?.map((current) => {
        return {
          label: current["fleetName"],
          value: current["_id"],
        };
      });

      setFleetOptions(options);
    }
  }, [fleet]);

  useEffect(() => {
    const fetchRobotData = async () => {
      dispatch(robotActions.setLoading({ loading: true }));
      try {
        const response = await axiosInstance.get(`robot`,{params:{robotId:robot?.robotId}});
        const { state, data } = response?.data;
        if (state) {
          if (data?.videoStreamURL) {
            const [streamURL, streamId] =
              data?.videoStreamURL?.split("?streamId=");
            if (streamURL && streamId) {
              data.streamId = streamId;
              data.streamURL = streamURL;
            }
          }
          setInitialValues(data);
          setModalValues(data);
        }
      } catch (error) {
        dispatch(robotActions.setEditRobotModal({ value: false }));
        console.error("Error fetching robot data:", error);
        toast.error("Failed to fetch robot data");
      } finally {
        dispatch(robotActions.setLoading({ loading: false }));
      }
    };

    if (isOpen && robot?.robotId) {
      fetchRobotData();
    }
  }, [isOpen, robot?.robotId]);

  const handleSubmit = async () => {
    dispatch(robotActions.setLoading({ loading: true }));
    try {
      let updatedValues = { ...updateValues };
    if (updateValues.streamURL || updateValues.streamId) {
      updatedValues = {
        ...updatedValues,
        videoStreamURL: `${modalValues?.streamURL ?? ""}?streamId=${modalValues?.streamId ?? ""}`
      };
      delete updatedValues.streamURL;
      delete updatedValues.streamId;
    }
      
      const result = await axiosInstance.put(
        `robot/${robot?.robotId}`,
        updatedValues
      );
      const { state, message } = result?.data;
      if (state) {
        toast.success("Details updated!");
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      dispatch(robotActions.setEditRobotModal({ value: false }));
      dispatch(robotActions.setLoading({ loading: false }));
    }
  };
  const dropDownCallback = (item) => {
    handleInputChange(item.value, "fleetId");
  };

  const handleInputChange = (value, data) => {
    const updatedModalValues = {
      ...modalValues,
      [data]: value,
    };
    const isChanged =
      JSON.stringify(initialValues) !== JSON.stringify(updatedModalValues);
    setModalValues(updatedModalValues);
    setIsChangeMade(isChanged);
    setUpdateValues((prevValues) => {
      const updatedValues = { ...prevValues };
      updatedValues[data] = value;
      return updatedValues;
    });
  };

  const handleReset = () => {
    setInitialValues(null);
    setModalValues(null);
    setIsChangeMade(false);
    setUpdateValues([]);
  };

  const handleClose = () => {
    onClose();
    handleReset();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit}
      textColor="white"
      // bgColor
      title="Edit Robot"
      titleColor="ottonomyBlue"
      submitText="Edit"
      disabled={!isChangeMade}
    >
      <div className="grid grid-cols-2 gap-4 max-h-[400px] overflow-auto">
        <div className="flex flex-col gap-y-1 col-span-2">
          <label className={`block text-textColor font-bold mb-2`}>
            Display Name
          </label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline focus:bg-white"
            value={modalValues?.displayRobotName || ""}
            onChange={(e) =>
              handleInputChange(e.target.value, "displayRobotName")
            }
          />
        </div>
        <div className="flex gap-x-1 col-span-2 items-center">
          <label className="block text-textColor font-bold">
            Commissioned for Orders
          </label>
          <Switch
            checked={modalValues?.robotCommissioned}
            onChange={(event, value) =>
              handleInputChange(value, "robotCommissioned")
            }
            inputProps={{ "aria-label": "Robot Commissioned Switch" }}
            style={{ color: "#fff" }}
          />
        </div>
        <div className="flex flex-col gap-y-1 col-span-2">
          <label className={`block text-textColor font-bold mb-2`}>Fleet ID</label>
          <Dropdown
            bgColor="white"
            textColor="black"
            buttonLabel={
              `${modalValues?.fleetId}(${fleetOptions?.find(fleet => fleet?.value === modalValues?.fleetId)?.label})` || "Choose Fleet"
            }
            items={fleetOptions}
            callBack={dropDownCallback}
            selectDefaultValue={fleetOptions?.find(fleet => fleet?.value === modalValues?.fleetId)?.label}
            propertyName="label"
          />
        </div>
        <div className="flex flex-col gap-y-1 col-span-2">
          <label className={`block text-textColor font-bold mb-2`}>
            SSH EndPoint
          </label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline focus:bg-white"
            value={modalValues?.sshEndpoint || ""}
            onChange={(e) => handleInputChange(e.target.value, "sshEndpoint")}
          />
        </div>
        <div className="flex flex-col gap-y-1 col-span-2">
          <label className={`block text-textColor font-bold mb-2`}>EndPoint</label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline focus:bg-white"
            value={modalValues?.endpoint || ""}
            onChange={(e) => handleInputChange(e.target.value, "endpoint")}
          />
        </div>
        <div className="flex flex-col gap-y-1 col-span-2">
          <label className={`block text-textColor font-bold mb-2`}>
            Stream URL
          </label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline focus:bg-white"
            value={modalValues?.streamURL || ""}
            onChange={(e) => handleInputChange(e.target.value, "streamURL")}
          />
        </div>
        {/* <div className="flex flex-col gap-y-1 col-span-2">
          <label className={`block text-textColor font-bold mb-2`}>Stream ID</label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline focus:bg-white"
            value={modalValues?.streamId || ""}
            onChange={(e) => handleInputChange(e.target.value, "streamId")}
          />
        </div> */}
        
      </div>
    </Modal>
  );
};

export default EditRobot;

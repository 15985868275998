import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardSideBar from "../../subcomponents/DashboardSideBar";
import DashboardBottomBar from "../DashboardBottomBar";
import AddLocationModal from "../../subcomponents/AddLocationModal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Header from "../Header";
import Location from "./Location";
import Loader from "../../subcomponents/Loader";
import EditLocationModal from "../../subcomponents/EditLocationModal";
import DeleteLocationModal from "../../subcomponents/DeleteLocationModal";
import axios from "axios";
import { image } from "d3";
import { getFleetListUser } from "../../API";
import {
  clearErrors,
  fleetListAndRobotStatusByUserEmail,
} from "../../redux/actions/fleet";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout";

function Locations(props) {
  const [locationsList, setLocationsList] = useState([]);
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);
  const [editLocationModalOpen, setEditLocationModalOpen] = useState(false);
  const [deleteLocationModalOpen, setDeleteLocationModalOpen] = useState(false);
  const [selectedLocationDetails, setSelectedLocationDetails] = useState();
  const [groupTypeOptions, setGroupTypeOptions] = useState(["Custom"]);
  const [editLocation, setEditLocation] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isopen = useSelector((state) => state.dashboardopenReducer);
  const { sidebarcollapse } = useSelector((state) => state.login);
  // const [fleetData, setFleetData] = useState(localStorage.getItem("fleetData"))

  const { fleetList, error: fleetError } = useSelector(
    (state) => state.fleetList
  );

  // console.log(fleetList, "--mfleet")

  const emailId = localStorage.getItem("useremail");

  useEffect(() => {
    if (fleetError) {
      toast.error(fleetError);
      dispatch(clearErrors());
    }
  }, [dispatch, fleetError]);

  const updateState = useCallback(async () => {
    if (fleetError !== null) {
      dispatch(fleetListAndRobotStatusByUserEmail(emailId));
    }
  }, [dispatch, emailId, fleetError]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);

  const fleetData = JSON.parse(localStorage.getItem("fleetData"));
  // console.log(fleetData, "fleet data")

  const fetchLocationsByType = async () => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_BASE_URL}v2/getLocationsByLocationType`,
        {
          fleetId: fleetData._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return result;
    } catch (err) {
      console.error(err);
      toast.error(err.message);
      // toast.error("Something went wrong");
    }
  };
  const updateLocationGroupIds = (locationsToMap) => {
    let uniqueGroupIds = new Set(["Custom"]);
    if (locationsToMap?.length) {
      locationsToMap.map((locationType) => {
        if (locationType?.locations?.length) {
          locationType?.locations?.map((location) => {
            if (location?.group) {
              uniqueGroupIds.add(location?.group);
            }
          });
        }
      });
    }
    setGroupTypeOptions(Array.from(uniqueGroupIds)?.reverse());
    return;
  };

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    fetchLocationsByType()
      .then((result) => {
        if (result.status === 200) {
          setLocationsList(result?.data?.data);
          updateLocationGroupIds(result?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <AddLocationModal
        addLocationModalOpen={addLocationModalOpen}
        setAddLocationModalOpen={setAddLocationModalOpen}
        fetchLocationsByType={fetchLocationsByType}
        setLocationsList={setLocationsList}
        updateLocationGroupIds={updateLocationGroupIds}
        groupTypeOptions={groupTypeOptions}
        editLocation={editLocation}
        editData={{ locationDetails: selectedLocationDetails }}
      />
      <EditLocationModal
        locationDetails={selectedLocationDetails}
        editLocationModalOpen={editLocationModalOpen}
        setEditLocationModalOpen={setEditLocationModalOpen}
        fetchLocationsByType={fetchLocationsByType}
        setLocationsList={setLocationsList}
        updateLocationGroupIds={updateLocationGroupIds}
        groupTypeOptions={groupTypeOptions}
      />
      <DeleteLocationModal
        locationDetails={selectedLocationDetails}
        deleteLocationModalOpen={deleteLocationModalOpen}
        setDeleteLocationModalOpen={setDeleteLocationModalOpen}
        fetchLocationsByType={fetchLocationsByType}
        setLocationsList={setLocationsList}
      />
      {/* <Header /> */}
      <Layout dashboardopen={props.dashboardopen} activeTab={props.activeTab} setActiveTab={props.setActiveTab}>
        <div className="container-fluid">
          <div className="row">
            {/* <div
              className={`hidden midLg:block ${
                sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"
              }`}
            >
              <DashboardSideBar
                dashboardopen={props.dashboardopen}
                active="locations"
              />
              <DashboardBottomBar active="locations" />
            </div> */}

            <div
              className={`${
                sidebarcollapse ? "collapseWidth" : ""
              }`}
            >
              <div
                className={
                  isopen.userlogindata ? "" : "flex flex-col gap-4 h-full"
                }
              >
                <div className="justify-between Dashboard_page_robot_card_heading_wrapper shadow-md">
                  <p className="FleetView_page_haeding_tag cursor-pointer">
                    {" "}
                    <h4
                      onClick={() => {
                        navigate("/fleetPage");
                      }}
                      style={{ fontSize: "19px" }}
                    >
                      Locations{" "}
                      <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                      <span
                        className="FleetView_page_Heading_fleetName"
                        style={{ fontSize: "19px" }}
                      >
                        {props.fleetData
                          ? props.fleetData.fleetName
                          : fleetData.fleetName}
                      </span>
                    </h4>
                  </p>

                  <button
                    className="px-2.5 py-0.5 rounded-md bg-ottonomyBlue"
                    onClick={() => {
                      setAddLocationModalOpen(true);
                      setEditLocation(false);
                      setSelectedLocationDetails({});
                    }}
                  >
                    <p className="text-white text-md">Add Location</p>
                  </button>
                </div>

                <div className="grid grid-cols-4 gap-3">
                  {locationsList?.length ? (
                    locationsList.map((location, index) => (
                      <div
                        className="col-span-full md:col-span-2"
                        key={"location__no__" + index}
                      >
                        <Location
                          details={location}
                          setSelectedLocationDetails={setSelectedLocationDetails}
                          setEditLocationModalOpen={setAddLocationModalOpen}
                          setEditLocation={setEditLocation}
                          setDeleteLocationModalOpen={setDeleteLocationModalOpen}
                          fetchLocationsByType={fetchLocationsByType}
                          setLocationsList={setLocationsList}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="flex w-full items-center justify-center text-textColor bg-primaryColor p-3 rounded-lg col-span-2">
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Locations;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLocationListLive, orderBook } from "../API";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import { useCallback } from "react";
import axios from "axios";
import { useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Checkbox,
  FormControlLabel,
  Switch,
  useMediaQuery,
} from "@mui/material";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material";
import Loader from "./Loader";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Calendar from "../components/Calendar";
import RemoveOrderModal from "./RemoveOrderModal";
import RemoveTransitOrderModal from "./RemoveTransitOrderModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

function OrderList(props) {
  const [Paginationlength, setPaginationlength] = useState(false);
  const [page, setpage] = useState(1);
  const [orderModalOpen, setorderModalOpen] = React.useState(false);
  const [showcount, setshowcount] = useState();
  const [orderlistData, setOrderlistData] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const { fleetList } = useSelector((state) => state.fleetList);
  const [currentpage, setcurrentpage] = useState(1);
  const [orderaccepted, setorderaccepted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [preperationOrderData, setPreperationOrderData] = useState([]);
  const [dropLocationOptions, setDropLocationOptions] = useState([
    { value: "Custom", label: "Custom" },
  ]);
  const [preparedOrderData, setPreparedOrderData] = useState([]);
  const [editPreparationTime, setEditPreparationTime] = useState(null);
  const [editCancellationTime, setEditCancellationTime] = useState(null);
  const [updatedPreparationTime, setUpdatedPreparationTime] = useState("");
  const [updatedCancellationTime, setUpdatedCancellationTime] = useState("");
  const [robotData, setRobotData] = useState([]);
  const [currentSize, setCurrentSize] = useState(10);
  const [openRemoveOrderModal, setOpenRemoveOrderModal] = useState(false);
  const [deletedOrderId, setDeletedOrderId] = useState("");
  const [openRemoveTransitOrder, setOpenRemoveTransitOrderModal] =
    useState(false);
  const [deletedTransitOrderId, setDeletedTransitOrderId] = useState("");

  const audioRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const fleetData = JSON.parse(localStorage.getItem("fleetData"));

  const { startDate, endDate } = useSelector(
    (state) => state.datefilterreducer
  );
  const size = "10";
  const showOrderSizeOption = true;
  const [totalcount, settotalcount] = useState(0);
  const [filtercollapse, setfiltercollapse] = useState(false);
  const token = localStorage.getItem("token");
  const theme = createTheme({
    components: {
      MuiPagination: {
        styleOverrides: {
          ul: {
            "& .Mui-selected": {
              backgroundColor: "#1C1E2A !important", // Your desired background color for the selected option
              color: "white", // Your desired text color for the selected option
              "&:hover": {
                backgroundColor: "#0099C0", // Your desired background color for the selected option on hover
              },
            },
          },
        },
      },
    },
  });
  const updateState = useCallback(async () => {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}order/transit`,
        {
          fleetId: [props?.fleetData?._id],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res?.data?.status === 200) {
          setOrderlistData(res?.data?.data?.order);
          setPreperationOrderData(res?.data?.data?.inPrepOrder);
          setPreparedOrderData(res?.data?.data?.PrepOrder);
          settotalcount(res?.data?.data?.count);
          setCurrentSize(res?.data?.data?.pageOrdersCount);
          if (res?.data?.data?.count > size) {
            setPaginationlength(true);
          }

          if (res?.data?.data?.count % 1 === 0) {
            setshowcount(Math.floor(res?.data?.data?.count / 10) + 1);
          } else {
            setshowcount(res?.data?.data?.order?.length / 10);
          }
        } else {
          // toast.error("Something went wrong");
        }
      }).catch(err => {
        console.error(err);
        toast.error(err.message)
      });
  }, [currentpage, size, startDate, endDate]);

  function getClientLocationLabel(value) {
    try {
      const parsedValue = JSON.parse(value);
      return parsedValue.locationName || "Custom";
    } catch (error) {
      return "Custom";
    }
  }
  const onValueChange = (value) => {
    formik.setFieldValue("phone", value);
  };

  const updateRobotETA = useCallback(async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}getRobotsETA/${
        props?.fleetData ? props?.fleetData?._id : fleetData._id
      }`
    );
  }, []);

  useEffect(() => {
    getLocationListLive(props.fleetData._id)
      .then((res) => {
        const client_location = res?.data?.data?.map((location) => ({
          value: JSON.stringify({
            positionX: location?.metricPositionX,
            positionY: location?.metricPositionY,
            positionYaw: location?.metricPositionYaw,
            locationName: location.locationName,
            latitude: location?.latitude,
            longitude: location?.longitude,
            headingAngle: location?.headingAngle,
          }),
          label: location.locationName,
        }));
        setDropLocationOptions([
          ...client_location,
          { value: "Custom", label: "Custom" },
        ]);
      })
      .catch((error) => {
        toast.error("Unable to fetch Location List");
      });
  }, []);
  const playSound = () => {
    audioRef.current.play();
  };
  const stopSound = () => {
    audioRef.current.pause();
  };

  async function cancelCurrentAction(actionId, robotId) {
    try {
      const cancelAction = await axios.post(
        `${process.env.REACT_APP_BASE_URL}robot/cancelActionByID`,
        {
          robotId,
          actionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(cancelAction.data.message);
    } catch (err) {
      toast.error(err.message);
    }
  }

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();
    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);
  useIntervalAsync(updateRobotETA, 1000 * 2);
  useEffect(() => {
    setorderaccepted(true);
    setrefresh(!refresh);
  }, []);

  useEffect(() => {
    if (
      (preperationOrderData.length || orderlistData.length) &&
      audioRef &&
      audioRef.current
    ) {
      const anyPrepTimeLessThanZero = preperationOrderData.find(
        (order) => order.timeLeft <= 0
      );
      const anyOrderWithFlag = orderlistData.find(
        (order) => order.noShowFlag === 1
      );
      if (anyPrepTimeLessThanZero || anyOrderWithFlag) {
        playSound();
      } else {
        stopSound();
      }
    }
  }, [orderlistData, preperationOrderData]);
  const handleChangetwo = (event, value) => {
    setpage(value);
    setcurrentpage(value);
  };
  const editOrderPrepTime = (id) => {
    setUpdatedPreparationTime("");
    setEditPreparationTime(id);
  };
  const editOrderCancellationTime = (id) => {
    setUpdatedCancellationTime("");
    setEditCancellationTime(id);
  };

  const deleteOrder = async (id) => {
    try {
      const deleteAction = await axios.post(
        `${process.env.REACT_APP_BASE_URL}order/cancel`,
        {
          fleetId: props?.fleetData?._id,
          orderId: id,
          message: "order removed",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(deleteAction.data.message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const deleteOrderTransit = async (id) => {
    try {
      const deleteAction = await axios.post(
        `${process.env.REACT_APP_BASE_URL}order/cancel`,
        {
          fleetId: props?.fleetData?._id,
          orderId: id,
          message: "order removed",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(deleteAction.data.message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handlePreparationInputChange = (event) => {
    if (event.target.value > 200) {
      toast.error("Preparation time cannot be more than 200 minutes");
      return;
    }
    if (!isNaN(event.target.value) && event.target.value >= 0) {
      setUpdatedPreparationTime(event.target.value);
    }
  };
  const handleCancellationInputChange = (event) => {
    if (event.target.value > 200) {
      toast.error("Cancellation time cannot be more than 200 minutes");
      return;
    }
    if (!isNaN(event.target.value) && event.target.value >= 0) {
      setUpdatedCancellationTime(event.target.value);
    }
  };
  const modalstyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "var(--primaryColor)",
    border: "2px solid #000",
    boxShadow: 24,
  };
  const validationSchema = yup.object({
    orderId: yup
      .string()
      .typeError("Order ID must be a string")
      .required("Order ID is required.")
      .matches(/^[0-9a-zA-Z-]*$/, "Order ID not allowed"),
    orderSize: yup.string().required("Size is req."),
    deliveryLocation: yup.string().required("Location is req."),
    preparationTime: yup.string().required("time required"),
    phone: yup
      .string()
      .required("phone required")
      .min(11, "Phone number must be at least 11 characters"),
  });

  const formik = useFormik({
    initialValues: {
      orderId: "",
      orderSize: `{"height": 38, "length":25 , "width": 27, "weight": 2000}`,
      deliveryLocation: "",
      height: "",
      width: "",
      length: "",
      weight: "",
      positionX: "",
      positionY: "",
      positionYaw: "",
      preparationTime: "10",
      phone: "",
      orderType: "LIVE",
      alreadyPrepared: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      orderBook(values, props?.fleetData?._id, token)
        .then((res) => {
          if (res?.status === 200) {
            setLoader(false);
            setorderModalOpen(false);
            toast.success(res.data.message);
            resetForm({ values: "" });
          } else {
            setLoader(false);
            setorderModalOpen(false);
            // toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
          setorderModalOpen(false);
          toast.error(err.message);
        });
      setLoader(true);
    },
  });
  const assignOrder = (event) => {
    setorderModalOpen(true);
  };
  const orderModalClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setorderModalOpen(false);
    formik.resetForm();
  };
  const changeOrderPrepTime = async (id) => {
    try {
      if (!updatedPreparationTime.length) {
        toast.error("Please input some value");
        return;
      }
      const deleteAction = await axios.post(
        `${process.env.REACT_APP_BASE_URL}order/edit`,
        {
          prepTime: updatedPreparationTime,
          orderId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setUpdatedPreparationTime("");
      setEditPreparationTime(null);
      toast.success(deleteAction.data.message);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const changeOrderCancTime = async (id, isDelete) => {
    try {
      if (!isDelete && !updatedCancellationTime.length) {
        toast.error("Please input some value");
        return;
      }
      const deleteAction = await axios.post(
        `${process.env.REACT_APP_BASE_URL}order/noshow`,
        {
          delay: isDelete ? null : updatedCancellationTime,
          orderId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setUpdatedCancellationTime("");
      setEditCancellationTime(null);
      toast.success(deleteAction.data.message);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const moveOrderToNextStep = async (id) => {
    try {
      const deleteAction = await axios.post(
        `${process.env.REACT_APP_BASE_URL}order/preparationStatus`,
        {
          preparationStatusCode: 1,
          orderId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Order moved");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const orderStatusColor = (orderStatus, statusCode) => {
    switch (orderStatus) {
      case "order delivered":
        return (
          <p className="p-color-green orderStatus_p">
            {orderStatus} {statusCode && <>({statusCode})</>}
          </p>
        );
      case "Order is Cancelled":
        return (
          <p className="p-color-red orderStatus_p">
            {" "}
            {orderStatus}
            {statusCode && <>({statusCode})</>}
          </p>
        );
      case "order not confirmed":
        return (
          <p className="p-color-yellow orderStatus_p">
            {" "}
            {orderStatus}
            {statusCode && <>({statusCode})</>}
          </p>
        );
      default:
        return (
          <p className="text-ottonomyBlue text-[13px] orderStatus_p">
            {orderStatus} {statusCode != null && <>({statusCode})</>}
          </p>
        );
    }
  };

  const handlefiltercollapse = () => {
    setfiltercollapse(!filtercollapse);
  };
  return (
    <>
      {process.env.REACT_APP_ALLOW_ORDERING_ARAMEX && (
        <>
          <button
            className="z-20 font-bold btn Assign-btn hover:shadow-md"
            onClick={assignOrder}
          >
            Add New Order
          </button>
          <Modal
            open={orderModalOpen}
            onHide={orderModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            backdrop="static"
            keyboard="false"
          >
            <Box
              sx={{
                ...modalstyle1,
                width: isMobile ? "100%" : 1000,
                height: isMobile ? "100%" : "auto",
              }}
            >
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader />
                  <span style={{ color: "white" }}>Booking Order...</span>
                </div>
              ) : (
                <div className="Order_modal_wrapper">
                  <div className="p-4 d-flex-center OrderModal_heading_wrapper">
                    <h3 className="Order_modal_h3"> Add New Order</h3>
                  </div>
                  <div />

                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="AddFleet_form_field_wrapper addlocation_textfield form__control__wrapper flex-wrap gap-4 px-2 sm:px-5 max-h-[calc(100vh-250px)] sm:max-h-[250px] overflow-scroll">
                        <div
                          className="w-[100%] sm:w-[30%]"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          <label
                            style={{
                              marginLeft: "0px",
                              fontWeight: "600",
                            }}
                          >
                            Phone Number*
                          </label>
                          <div
                            className="inline"
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          >
                            <PhoneInput
                              style={{
                                textAlign: "justify",
                                fontSize: "14px",
                                position: "relative",
                              }}
                              country={"us"}
                              specialLabel="Phone Number"
                              enableSearch={true}
                              autoFocus={true}
                              value={formik.values.phone}
                              onChange={(value) => onValueChange(value)}
                              countryCodeEditable={false}
                              margin="standard"
                              name="phone"
                              label="Phone Number"
                              required
                              containerStyle={{}}
                              inputStyle={{
                                padding: "8px 60px",
                                border: "0",
                                borderRadius: "0",
                                width: "100%",
                              }}
                              error={
                                formik.touched.phone &&
                                Boolean(formik.errors.phone)
                              }
                              helperText={
                                formik.touched.phone && formik.errors.phone
                              }
                              dropdownStyle={{
                                position: "fixed",
                              }}
                            />
                          </div>
                          <div>
                            {" "}
                            {formik.errors.phone && formik.touched.phone ? (
                              <p
                                style={{
                                  color: "#d32f2f",
                                  fontSize: "0.75rem",
                                  marginTop: "-2px",
                                  textAlign: "left",
                                  paddingLeft: "0px",
                                }}
                              >
                                Please Enter Phone Number
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className="w-[100%] sm:w-[30%]"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          <label
                            style={{
                              marginLeft: "0px",
                              fontWeight: "600",
                            }}
                          >
                            Order ID*
                          </label>
                          <TextField
                            id="standard-size-normal"
                            fullWidth
                            value={formik.values.orderId}
                            name="orderId"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.orderId &&
                              Boolean(formik.errors.orderId)
                            }
                            helperText={
                              formik.touched.orderId && formik.errors.orderId
                            }
                          />
                        </div>
                        <div
                          className="w-[100%] sm:w-[30%]"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          <label
                            style={{
                              marginLeft: "0px",
                              fontWeight: "600",
                            }}
                          >
                            Select Drop Location
                          </label>
                          <FormControl
                            style={{
                              backgroundColor: "rgb(28, 30, 43)",
                              WebkitTextFillColor:
                                "rgb(189, 196, 224) !important",
                            }}
                          >
                            <Autocomplete
                              options={dropLocationOptions}
                              error={
                                formik.touched.orderId &&
                                Boolean(formik.errors.orderId)
                              }
                              helperText={
                                formik.touched.deliveryLocation &&
                                formik.errors.deliveryLocation
                              }
                              value={
                                formik.values.deliveryLocation === ""
                                  ? null
                                  : dropLocationOptions.find(
                                      (option) =>
                                        option.value ===
                                        formik.values.deliveryLocation
                                    ) || null
                              }
                              onChange={(_, selectedOption) => {
                                formik.setFieldValue(
                                  "deliveryLocation",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  style={{
                                    // Apply styles here
                                    color: "rgb(189, 196, 224)",
                                    margin: "0",
                                    padding: "5px 5px 5px 10px",
                                    fontSize: "14px",
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                          <div>
                            {" "}
                            {formik.errors.deliveryLocation &&
                            formik.touched.deliveryLocation ? (
                              <p
                                style={{
                                  color: "#d32f2f",
                                  fontSize: "0.75rem",
                                  marginTop: "-2px",
                                  textAlign: "left",
                                  paddingLeft: "0px",
                                }}
                              >
                                Drop Location is req.
                              </p>
                            ) : null}
                          </div>
                        </div>

                        {showOrderSizeOption && (
                          <div
                            className="w-[40%] sm:w-[30%]"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                            }}
                          >
                            <label
                              style={{
                                marginLeft: "0px",
                                fontWeight: "600",
                              }}
                            >
                              Order Size
                            </label>
                            <FormControl
                              style={{
                                backgroundColor: "rgb(28, 30, 43)",
                                WebkitTextFillColor:
                                  "rgb(189, 196, 224) !important",
                              }}
                            >
                              <Select
                                variant="standard"
                                labelId="orderSize-label"
                                id="orderSize-select"
                                name="orderSize"
                                value={
                                  formik.values.orderSize === ""
                                    ? "Select Order Size"
                                    : formik.values.orderSize
                                }
                                label="orderSize"
                                style={{
                                  color: "rgb(189, 196, 224)",
                                  margin: "0",
                                  padding: "5px 5px 5px 10px",
                                  fontSize: "14px",
                                }}
                                error={
                                  formik.values.orderSize === "" &&
                                  Boolean(formik.errors.orderSize)
                                }
                                onChange={formik.handleChange}
                              >
                                <MenuItem
                                  value="Select Order Size"
                                  selected
                                  disabled
                                >
                                  Select Order Size
                                </MenuItem>
                                <MenuItem
                                  value={`{"height": 38, "length":25 , "width": 27, "weight": 2000}`}
                                >
                                  Default Cabin
                                </MenuItem>
                                <MenuItem value={`Custom`}>Custom</MenuItem>
                              </Select>
                            </FormControl>
                            <div>
                              {" "}
                              {formik.errors.orderSize &&
                              formik.touched.orderSize ? (
                                <p
                                  style={{
                                    color: "#d32f2f",
                                    fontSize: "0.75rem",
                                    marginTop: "-2px",
                                    textAlign: "left",
                                    paddingLeft: "0px",
                                  }}
                                >
                                  Order Size is req.
                                </p>
                              ) : null}
                            </div>
                          </div>
                        )}
                        <div
                          className="w-[40%] sm:w-[30%]"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          <label
                            style={{
                              marginLeft: "0px",
                              fontWeight: "600",
                            }}
                          >
                            Order Type
                          </label>
                          <FormControl
                            style={{
                              backgroundColor: "rgb(28, 30, 43)",
                              WebkitTextFillColor:
                                "rgb(189, 196, 224) !important",
                            }}
                          >
                            <Select
                              variant="standard"
                              labelId="orderType-label"
                              id="orderType-select"
                              name="orderType"
                              value={
                                formik.values.orderType === ""
                                  ? "Select Order type"
                                  : formik.values.orderType
                              }
                              label="orderType"
                              style={{
                                color: "rgb(189, 196, 224)",
                                margin: "0",
                                padding: "5px 5px 5px 10px",
                                fontSize: "14px",
                              }}
                              error={
                                formik.values.orderType === "" &&
                                Boolean(formik.errors.orderType)
                              }
                              onChange={formik.handleChange}
                            >
                              <MenuItem
                                value="Select Order Type"
                                selected
                                disabled
                              >
                                Select Order Type
                              </MenuItem>
                              <MenuItem value="LIVE">LIVE ORDER</MenuItem>
                              <MenuItem value="TEST">TEST ORDER</MenuItem>
                            </Select>
                          </FormControl>
                          <div>
                            {" "}
                            {formik.errors.orderType &&
                            formik.touched.orderType ? (
                              <p
                                style={{
                                  color: "#d32f2f",
                                  fontSize: "0.75rem",
                                  marginTop: "-2px",
                                  textAlign: "left",
                                  paddingLeft: "0px",
                                }}
                              >
                                Order Type is req.
                              </p>
                            ) : null}
                          </div>
                        </div>
                        {formik.values.orderSize === "Custom" && (
                          <>
                            <div
                              className="w-[40%] sm:w-[20%]"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <label
                                style={{
                                  marginLeft: "0px",
                                  fontWeight: "600",
                                }}
                              >
                                Height
                              </label>
                              <TextField
                                id="standard-size-normal"
                                variant="standard"
                                value={formik.values.height}
                                name="height"
                                type="number"
                                onChange={formik.handleChange}
                                style={{ fontSize: "14px" }}
                                placeholder="Height"
                                error={
                                  formik.touched.height &&
                                  Boolean(formik.errors.height)
                                }
                                required
                                helperText={
                                  formik.touched.height && formik.errors.height
                                }
                              />
                            </div>
                            <div
                              className="w-[40%] sm:w-[20%]"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <label
                                style={{
                                  marginLeft: "0px",
                                  fontWeight: "600",
                                }}
                              >
                                Length
                              </label>
                              <TextField
                                id="standard-size-normal"
                                variant="standard"
                                value={formik.values.length}
                                name="length"
                                type="number"
                                onChange={formik.handleChange}
                                style={{ fontSize: "14px" }}
                                placeholder="Length"
                                error={
                                  formik.touched.length &&
                                  Boolean(formik.errors.length)
                                }
                                required
                                helperText={
                                  formik.touched.length && formik.errors.length
                                }
                              />
                            </div>
                            <div
                              className="w-[40%] sm:w-[20%]"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <label
                                style={{
                                  marginLeft: "0px",
                                  fontWeight: "600",
                                }}
                              >
                                Width
                              </label>
                              <TextField
                                id="standard-size-normal"
                                variant="standard"
                                value={formik.values.width}
                                name="width"
                                type="number"
                                onChange={formik.handleChange}
                                style={{ fontSize: "14px" }}
                                placeholder="Width"
                                error={
                                  formik.touched.width &&
                                  Boolean(formik.errors.width)
                                }
                                required
                                helperText={
                                  formik.touched.width && formik.errors.width
                                }
                              />
                            </div>
                            <div
                              className="w-[40%] sm:w-[20%]"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <label
                                style={{
                                  marginLeft: "0px",
                                  fontWeight: "600",
                                }}
                              >
                                Weight
                              </label>
                              <TextField
                                id="standard-size-normal"
                                variant="standard"
                                value={formik.values.weight}
                                name="weight"
                                type="number"
                                onChange={formik.handleChange}
                                style={{ fontSize: "14px" }}
                                placeholder="Weight"
                                error={
                                  formik.touched.weight &&
                                  Boolean(formik.errors.weight)
                                }
                                required
                                helperText={
                                  formik.touched.weight && formik.errors.weight
                                }
                              />
                            </div>
                          </>
                        )}
                        {formik.values.deliveryLocation === "Custom" && (
                          <>
                            <div
                              className="w-[40%] sm:w-[20%]"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <label
                                style={{
                                  marginLeft: "0px",
                                  fontWeight: "600",
                                }}
                              >
                                Position Yaw
                              </label>
                              <TextField
                                id="standard-size-normal"
                                variant="standard"
                                value={formik.values.positionYaw}
                                name="positionYaw"
                                type="number"
                                onChange={formik.handleChange}
                                style={{ fontSize: "14px" }}
                                placeholder="Position Yaw"
                                error={
                                  formik.touched.positionYaw &&
                                  Boolean(formik.errors.positionYaw)
                                }
                                required
                                helperText={
                                  formik.touched.positionYaw &&
                                  formik.errors.positionYaw
                                }
                              />
                            </div>
                            <div
                              className="w-[40%] sm:w-[20%]"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <label
                                style={{
                                  marginLeft: "0px",
                                  fontWeight: "600",
                                }}
                              >
                                Position X
                              </label>
                              <TextField
                                id="standard-size-normal"
                                variant="standard"
                                value={formik.values.positionX}
                                name="positionX"
                                type="number"
                                onChange={formik.handleChange}
                                style={{ fontSize: "14px" }}
                                placeholder="Position X"
                                error={
                                  formik.touched.positionX &&
                                  Boolean(formik.errors.positionX)
                                }
                                required
                                helperText={
                                  formik.touched.positionX &&
                                  formik.errors.positionX
                                }
                              />
                            </div>
                            <div
                              className="w-[40%] sm:w-[20%]"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                              }}
                            >
                              <label
                                style={{
                                  marginLeft: "0px",
                                  fontWeight: "600",
                                }}
                              >
                                Position Y
                              </label>
                              <TextField
                                id="standard-size-normal"
                                variant="standard"
                                value={formik.values.positionY}
                                name="positionY"
                                type="number"
                                onChange={formik.handleChange}
                                style={{ fontSize: "14px" }}
                                placeholder="Position Y"
                                error={
                                  formik.touched.positionY &&
                                  Boolean(formik.errors.positionY)
                                }
                                required
                                helperText={
                                  formik.touched.positionY &&
                                  formik.errors.positionY
                                }
                              />
                            </div>
                          </>
                        )}
                        <div
                          className="w-[40%] sm:w-[30%]"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          <div className="flex items-center justify-between w-full">
                            <label
                              style={{
                                marginLeft: "0px",
                                fontWeight: "600",
                              }}
                            >
                              Prep Time (mins)
                            </label>
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "11px",
                                },
                                "& .MuiSvgIcon-root": {
                                  fontSize: 16,
                                },
                                "& .MuiButtonBase-root-MuiCheckbox-root": {
                                  padding: "0px",
                                },
                              }}
                              value={formik.values.alreadyPrepared}
                              name="alreadyPrepared"
                              onChange={formik.handleChange}
                              control={<Checkbox />}
                              label="Already Prepared"
                            />
                          </div>
                          <TextField
                            id="standard-size-normal"
                            variant="standard"
                            disabled={formik.values.alreadyPrepared}
                            value={formik.values.preparationTime}
                            name="preparationTime"
                            onChange={formik.handleChange}
                            style={{ fontSize: "14px" }}
                            error={
                              formik.touched.preparationTime &&
                              Boolean(formik.errors.preparationTime)
                            }
                            type="number"
                            helperText={
                              formik.touched.preparationTime &&
                              formik.errors.preparationTime
                            }
                          />
                        </div>
                      </div>

                      <div className="absolute inset-x-0 bottom-0 flex justify-between px-5 py-3 Order_modal_button_wrapper sm:relative">
                        <button
                          onClick={orderModalClose}
                          className="bg-ottonomyDarkBlue text-textColor px-5 py-2.5 rounded-lg border-ottonomyBlue border-2 font-bold transition-all hover:bg-ottonomyBlue"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="transition-all Order_save_button hover:bg-ottonomyBlue"
                        >
                          Submit{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Box>
          </Modal>
        </>
      )}
      {robotData && robotData.length > 0 && (
        <div className="flex flex-col w-full my-2 text-center sm:flex-row gap-y-2 sm:gap-0">
          {robotData.map((robot) => (
            <div
              className="px-2 py-2 border-2 border-yellow-300 rounded-md"
              key={robot.robotId}
            >
              <div className="flex flex-col space-y-1" key={robot.robotId}>
                <p className="font-bold text-ottonomyBlue">{robot.robotId}</p>
                <p className="text-textColor ">
                  {robot.robotStatus === "UNAVAILABLE"
                    ? "is not available"
                    : robot.ETA
                    ? robot?.currentRobotAction?.message === "MOVEHOME"
                      ? "is heading back home"
                      : `Will be available in {Math.ceil(robot.ETA / 60)} mins`
                    : "is Available at the PickUp Point"}
                </p>
                {robot.robotStatus === "AVAILABLE" &&
                  robot?.currentRobotAction?.message === "MOVEHOME" && (
                    <button
                      onClick={() =>
                        cancelCurrentAction(
                          robot.currentRobotAction.actionId,
                          robot.robotId
                        )
                      }
                      className="p-2 bg-ottonomyBlue"
                    >
                      <p className="text-textColor">Cancel Home</p>
                    </button>
                  )}
              </div>
            </div>
          ))}
        </div>
      )}
      <div>
        <audio ref={audioRef} src="/assets/audio/alert.wav"></audio>
        <div className="flex flex-col mt-4">
          <h1 className="flex text-xl font-bold text-ottonomyBlue">
            Orders under Preparation
          </h1>

          <div className="w-full pb-5 overflow-auto hidescrollbar">
            <table
              style={{ width: "100%", marginLeft: "0px" }}
              className="Fleet_page_table_Main_whole orderlist_table"
            >
              <thead>
                <tr className="text-lg">
                  <th> OrderId </th>
                  <th> Status</th>
                  <th> Delivery Point</th>
                  <th>Phone Number</th>
                  <th> Order Pin</th>
                  <th> Time Stamp </th>
                  <th>Remove Order</th>
                </tr>
              </thead>
              <tbody>
                {preperationOrderData && preperationOrderData.length ? (
                  preperationOrderData?.map((item, index) => {
                    return (
                      <tr className="text-sm" key={index}>
                        <td>
                          {" "}
                          <p className="text-yellow-300">{item.orderId}</p>
                        </td>
                        <td>
                          <div className="flex gap-x-3">
                            <p className="self-center font-medium text-yellow-300">
                              {item.timeLeft && item.timeLeft > 0
                                ? Math.ceil(item.timeLeft / 60) +
                                  " min prep.time"
                                : "Prepared"}
                            </p>
                            {editPreparationTime !== item.orderId && (
                              <div className="flex gap-x-1">
                                <button
                                  className="transition-all rounded-sm hover:bg-yellow-300 hover:shadow-md"
                                  onClick={() =>
                                    editOrderPrepTime(item.orderId)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 20.652 20.652"
                                    className="self-center"
                                  >
                                    <g
                                      id="noun-edit-1150515"
                                      transform="translate(-10.848 -10.849)"
                                    >
                                      <path
                                        id="Path_250"
                                        data-name="Path 250"
                                        d="M31.307,15.7l-4.662-4.662a.659.659,0,0,0-.932,0L22.9,13.854a.657.657,0,0,0-.193.466.656.656,0,0,0,.193.466l4.2,4.2-7.88,7.879L15.5,23.144l7.02-6.951a.659.659,0,1,0-.928-.937l-7.4,7.326a.668.668,0,0,0-.1.085.654.654,0,0,0-.077.094l-1.786,1.768a.656.656,0,0,0-.182.338l-1.181,5.844a.659.659,0,0,0,.646.79.643.643,0,0,0,.131-.013l5.843-1.181a.655.655,0,0,0,.335-.18l13.49-13.49a.659.659,0,0,0,0-.932ZM17.026,29.053,12.351,30l.944-4.673,1.265-1.253L18.284,27.8Zm11-11-3.73-3.73,1.881-1.881,3.73,3.73Z"
                                        fill="#02b7d4"
                                      />
                                    </g>
                                  </svg>
                                </button>
                                <button
                                  className="transition-all rounded-sm hover:bg-yellow-300 hover:shadow-md"
                                  onClick={() =>
                                    moveOrderToNextStep(item.orderId)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 19.774 18.085"
                                  >
                                    <g
                                      id="noun-tick-1157224"
                                      transform="translate(-21.184 -24.334)"
                                    >
                                      <path
                                        id="Path_251"
                                        data-name="Path 251"
                                        d="M30.006,42.419l-8.319-6.311a1.272,1.272,0,1,1,1.539-2.026l6.163,4.677L38.627,24.9a1.272,1.272,0,0,1,2.117,1.412Z"
                                        fill="#02b7d4"
                                      />
                                    </g>
                                  </svg>
                                </button>
                              </div>
                            )}
                            {editPreparationTime === item.orderId && (
                              <div className="flex gap-x-2">
                                <input
                                  type="number"
                                  placeholder="New Prep time"
                                  className="px-3 border rounded-lg"
                                  value={updatedPreparationTime}
                                  onChange={(event) =>
                                    handlePreparationInputChange(event)
                                  }
                                />
                                <button
                                  onClick={() =>
                                    changeOrderPrepTime(item.orderId)
                                  }
                                  className="p-1 text-textColor transition-all rounded-lg bg-ottonomyBlue hover:bg-blue-600"
                                >
                                  Add
                                </button>
                                <button
                                  onClick={() => {
                                    setUpdatedPreparationTime("");
                                    setEditPreparationTime(null);
                                  }}
                                  className="p-1 text-textColor transition-all rounded-lg bg-ottonomyBlue hover:bg-blue-600"
                                >
                                  close
                                </button>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          {item?.delivery?.locationName
                            ? item?.delivery?.locationName
                            : parseFloat(item?.delivery?.positionX).toFixed(8) +
                              ", " +
                              parseFloat(item?.delivery?.positionY).toFixed(8) +
                              ", " +
                              parseFloat(item?.delivery?.positionYaw).toFixed(
                                8
                              )}
                        </td>
                        <td> {item.phone}</td>
                        <td> {item.pin}</td>
                        <td>
                          {" "}
                          {new Date(item.createdAt)
                            .toString()
                            .replace("GMT+0530 (India Standard Time)", "")}
                          {/*{.replace('GMT+0530 (India Standard Time)','')}  */}
                        </td>
                        <td>
                          <button
                            className="p-2 ml-6 transition-all rounded-lg hover:bg-ottonomyBlue hover:shadow-md"
                            onClick={
                              () => {
                                setDeletedOrderId(item.orderId);
                                setOpenRemoveOrderModal(true);
                              }
                              // deleteOrder(item.orderId)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14.078"
                              height="14.057"
                              viewBox="0 0 14.078 14.057"
                            >
                              <g
                                id="noun-delete-5593968"
                                transform="translate(-84 -14.395)"
                              >
                                <path
                                  id="Path_119"
                                  data-name="Path 119"
                                  d="M157.573,115.376H151.03a2.05,2.05,0,0,1-2.048-2.048v-9.646h.741v9.646a1.309,1.309,0,0,0,1.307,1.307h6.542a1.309,1.309,0,0,0,1.307-1.307v-9.646h.741v9.646a2.05,2.05,0,0,1-2.048,2.048Z"
                                  transform="translate(-63.262 -86.924)"
                                  fill="#dc6068"
                                />
                                <path
                                  id="Path_120"
                                  data-name="Path 120"
                                  d="M97.707,90.421H84.37a.37.37,0,0,1,0-.741H97.707a.37.37,0,0,1,0,.741Z"
                                  transform="translate(0 -73.293)"
                                  fill="#dc6068"
                                />
                                <path
                                  id="Path_121"
                                  data-name="Path 121"
                                  d="M259.818,16.758h-.741V15.6a.469.469,0,0,0-.468-.468h-2.621a.469.469,0,0,0-.468.468v1.153h-.741V15.6a1.211,1.211,0,0,1,1.209-1.209h2.621a1.211,1.211,0,0,1,1.209,1.209Z"
                                  transform="translate(-166.259)"
                                  fill="#dc6068"
                                />
                                <path
                                  id="Path_122"
                                  data-name="Path 122"
                                  d="M262.17,210.978a.37.37,0,0,1-.37-.37V205.17a.37.37,0,1,1,.741,0v5.437A.37.37,0,0,1,262.17,210.978Z"
                                  transform="translate(-173.095 -185.366)"
                                  fill="#dc6068"
                                />
                                <path
                                  id="Path_123"
                                  data-name="Path 123"
                                  d="M410.57,210.978a.37.37,0,0,1-.37-.37V205.17a.37.37,0,1,1,.741,0v5.437A.37.37,0,0,1,410.57,210.978Z"
                                  transform="translate(-317.568 -185.366)"
                                  fill="#dc6068"
                                />
                              </g>
                            </svg>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      className="text-2xl font-bold text-center text-textColor"
                      colSpan="6"
                    >
                      Oops! No orders in preparation
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-col">
          <h1 className="flex text-xl font-bold text-ottonomyBlue">
            Orders Prepared
          </h1>
          <div className="w-full pb-5 overflow-auto">
            <table
              style={{ width: "100%", marginLeft: "0px" }}
              className="Fleet_page_table_Main_whole orderlist_table"
            >
              <thead>
                <tr>
                  <th> OrderId </th>
                  <th> Status</th>
                  <th> Delivery Point</th>
                  <th> Phone Number</th>
                  <th> Order Pin</th>
                  <th> Time Stamp </th>
                  <th> Remove order</th>
                </tr>
              </thead>
              <tbody>
                {preparedOrderData && preparedOrderData.length ? (
                  preparedOrderData?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td> {item.orderId}</td>
                        <td>
                          <p className="p-color-green orderStatus_p">
                            Order Prepared
                            {item.statusCode >= 0 ? `(${item.statusCode})` : ""}
                          </p>
                        </td>
                        <td>
                          {item?.delivery?.locationName
                            ? item?.delivery?.locationName
                            : parseFloat(item?.delivery?.positionX).toFixed(8) +
                              ", " +
                              parseFloat(item?.delivery?.positionY).toFixed(8) +
                              ", " +
                              parseFloat(item?.delivery?.positionYaw).toFixed(
                                8
                              )}
                        </td>
                        <td> {item.phone}</td>
                        <td> {item.pin}</td>
                        <td>
                          {" "}
                          {new Date(item.createdAt)
                            .toString()
                            .replace("GMT+0530 (India Standard Time)", "")}
                          {/*{.replace('GMT+0530 (India Standard Time)','')}  */}
                        </td>
                        <td>
                          <button
                            className="p-2 ml-6 transition-all rounded-lg hover:bg-ottonomyBlue hover:shadow-md"
                            onClick={
                              () => {
                                setDeletedOrderId(item.orderId);
                                setOpenRemoveOrderModal(true);
                              }
                              // deleteOrder(item.orderId)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14.078"
                              height="14.057"
                              viewBox="0 0 14.078 14.057"
                            >
                              <g
                                id="noun-delete-5593968"
                                transform="translate(-84 -14.395)"
                              >
                                <path
                                  id="Path_119"
                                  data-name="Path 119"
                                  d="M157.573,115.376H151.03a2.05,2.05,0,0,1-2.048-2.048v-9.646h.741v9.646a1.309,1.309,0,0,0,1.307,1.307h6.542a1.309,1.309,0,0,0,1.307-1.307v-9.646h.741v9.646a2.05,2.05,0,0,1-2.048,2.048Z"
                                  transform="translate(-63.262 -86.924)"
                                  fill="#dc6068"
                                />
                                <path
                                  id="Path_120"
                                  data-name="Path 120"
                                  d="M97.707,90.421H84.37a.37.37,0,0,1,0-.741H97.707a.37.37,0,0,1,0,.741Z"
                                  transform="translate(0 -73.293)"
                                  fill="#dc6068"
                                />
                                <path
                                  id="Path_121"
                                  data-name="Path 121"
                                  d="M259.818,16.758h-.741V15.6a.469.469,0,0,0-.468-.468h-2.621a.469.469,0,0,0-.468.468v1.153h-.741V15.6a1.211,1.211,0,0,1,1.209-1.209h2.621a1.211,1.211,0,0,1,1.209,1.209Z"
                                  transform="translate(-166.259)"
                                  fill="#dc6068"
                                />
                                <path
                                  id="Path_122"
                                  data-name="Path 122"
                                  d="M262.17,210.978a.37.37,0,0,1-.37-.37V205.17a.37.37,0,1,1,.741,0v5.437A.37.37,0,0,1,262.17,210.978Z"
                                  transform="translate(-173.095 -185.366)"
                                  fill="#dc6068"
                                />
                                <path
                                  id="Path_123"
                                  data-name="Path 123"
                                  d="M410.57,210.978a.37.37,0,0,1-.37-.37V205.17a.37.37,0,1,1,.741,0v5.437A.37.37,0,0,1,410.57,210.978Z"
                                  transform="translate(-317.568 -185.366)"
                                  fill="#dc6068"
                                />
                              </g>
                            </svg>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      className="text-2xl font-bold text-center text-textColor"
                      colSpan="8"
                    >
                      Oops! No orders in prepared
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-col">
          <h1 className="flex text-xl font-bold text-ottonomyBlue">
            Orders in Transit
          </h1>
          <div className="w-full pb-5 overflow-auto">
            <table
              style={{ width: "100%", marginLeft: "0px" }}
              className="Fleet_page_table_Main_whole orderlist_table"
            >
              <thead>
                <tr>
                  <th> Robot Id </th>
                  <th> OrderId </th>
                  <th> Status</th>
                  <th> Compartment</th>
                  <th> Delivery Point</th>
                  <th> Phone number</th>
                  <th> Order Pin</th>
                  {/* <th> PickUp (Lat,Long)</th>
                <th> Order Info</th> */}
                  <th> Time Stamp </th>

                  <th> Remove Order </th>
                </tr>
              </thead>
              <tbody>
                {orderlistData && orderlistData.length ? (
                  orderlistData?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td> {item.robotId}</td>
                        <td> {item.orderId}</td>
                        <td>
                          {" "}
                          <div className="flex gap-x-3">
                            <p className="self-center font-medium">
                              {orderStatusColor(
                                item.orderStatus,
                                item.statusCode
                              )}
                            </p>

                            {item.noShowFlag === 1 && item.statusCode === 8 && (
                              <div>
                                {editCancellationTime !== item.orderId && (
                                  <div className="flex gap-x-1">
                                    <button
                                      className="transition-all rounded-sm hover:bg-yellow-300 hover:shadow-md"
                                      onClick={() =>
                                        editOrderCancellationTime(
                                          item.orderId,
                                          true
                                        )
                                      }
                                      title="Edit Order Cancellation Time" // Add the title attribute for the tooltip
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 20.652 20.652"
                                        className="self-center"
                                      >
                                        <g
                                          id="noun-edit-1150515"
                                          transform="translate(-10.848 -10.849)"
                                        >
                                          <path
                                            id="Path_250"
                                            data-name="Path 250"
                                            d="M31.307,15.7l-4.662-4.662a.659.659,0,0,0-.932,0L22.9,13.854a.657.657,0,0,0-.193.466.656.656,0,0,0,.193.466l4.2,4.2-7.88,7.879L15.5,23.144l7.02-6.951a.659.659,0,1,0-.928-.937l-7.4,7.326a.668.668,0,0,0-.1.085.654.654,0,0,0-.077.094l-1.786,1.768a.656.656,0,0,0-.182.338l-1.181,5.844a.659.659,0,0,0,.646.79.643.643,0,0,0,.131-.013l5.843-1.181a.655.655,0,0,0,.335-.18l13.49-13.49a.659.659,0,0,0,0-.932ZM17.026,29.053,12.351,30l.944-4.673,1.265-1.253L18.284,27.8Zm11-11-3.73-3.73,1.881-1.881,3.73,3.73Z"
                                            fill="#02b7d4"
                                          />
                                        </g>
                                      </svg>
                                    </button>

                                    <button
                                      className="transition-all rounded-sm hover:bg-yellow-300 hover:shadow-md"
                                      onClick={() =>
                                        changeOrderCancTime(item.orderId, true)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14.078"
                                        height="14.057"
                                        viewBox="0 0 14.078 14.057"
                                      >
                                        <g
                                          id="noun-delete-5593968"
                                          transform="translate(-84 -14.395)"
                                        >
                                          <path
                                            id="Path_119"
                                            data-name="Path 119"
                                            d="M157.573,115.376H151.03a2.05,2.05,0,0,1-2.048-2.048v-9.646h.741v9.646a1.309,1.309,0,0,0,1.307,1.307h6.542a1.309,1.309,0,0,0,1.307-1.307v-9.646h.741v9.646a2.05,2.05,0,0,1-2.048,2.048Z"
                                            transform="translate(-63.262 -86.924)"
                                            fill="#dc6068"
                                          />
                                          <path
                                            id="Path_120"
                                            data-name="Path 120"
                                            d="M97.707,90.421H84.37a.37.37,0,0,1,0-.741H97.707a.37.37,0,0,1,0,.741Z"
                                            transform="translate(0 -73.293)"
                                            fill="#dc6068"
                                          />
                                          <path
                                            id="Path_121"
                                            data-name="Path 121"
                                            d="M259.818,16.758h-.741V15.6a.469.469,0,0,0-.468-.468h-2.621a.469.469,0,0,0-.468.468v1.153h-.741V15.6a1.211,1.211,0,0,1,1.209-1.209h2.621a1.211,1.211,0,0,1,1.209,1.209Z"
                                            transform="translate(-166.259)"
                                            fill="#dc6068"
                                          />
                                          <path
                                            id="Path_122"
                                            data-name="Path 122"
                                            d="M262.17,210.978a.37.37,0,0,1-.37-.37V205.17a.37.37,0,1,1,.741,0v5.437A.37.37,0,0,1,262.17,210.978Z"
                                            transform="translate(-173.095 -185.366)"
                                            fill="#dc6068"
                                          />
                                          <path
                                            id="Path_123"
                                            data-name="Path 123"
                                            d="M410.57,210.978a.37.37,0,0,1-.37-.37V205.17a.37.37,0,1,1,.741,0v5.437A.37.37,0,0,1,410.57,210.978Z"
                                            transform="translate(-317.568 -185.366)"
                                            fill="#dc6068"
                                          />
                                        </g>
                                      </svg>
                                    </button>
                                  </div>
                                )}
                                {editCancellationTime === item.orderId && (
                                  <div className="flex gap-x-2">
                                    <input
                                      type="number"
                                      placeholder="New Cancellation time"
                                      className="px-3 border rounded-lg"
                                      value={updatedCancellationTime}
                                      onChange={(event) =>
                                        handleCancellationInputChange(event)
                                      }
                                    />
                                    <button
                                      onClick={() =>
                                        changeOrderCancTime(item.orderId, false)
                                      }
                                      className="p-1 text-textColor transition-all rounded-lg bg-ottonomyBlue hover:bg-blue-600"
                                    >
                                      Add
                                    </button>
                                    <button
                                      onClick={() => {
                                        setUpdatedCancellationTime("");
                                        setEditCancellationTime(null);
                                      }}
                                      className="p-1 text-textColor transition-all rounded-lg bg-ottonomyBlue hover:bg-blue-600"
                                    >
                                      close
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </td>
                        <td> {item.cabinId}</td>
                        <td>
                          {item?.delivery?.locationName
                            ? item?.delivery?.locationName
                            : parseFloat(item?.delivery?.positionX).toFixed(8) +
                              ", " +
                              parseFloat(item?.delivery?.positionY).toFixed(8) +
                              ", " +
                              parseFloat(item?.delivery?.positionYaw).toFixed(
                                8
                              )}
                        </td>
                        <td> {item.phone}</td>
                        <td> {item.pin}</td>
                        <td>
                          {" "}
                          {new Date(item.createdAt)
                            .toString()
                            .replace("GMT+0530 (India Standard Time)", "")}
                          {/*{.replace('GMT+0530 (India Standard Time)','')}  */}
                        </td>
                        {item.statusCode < 9 && (
                          <button
                            className="p-2 ml-6 transition-all rounded-lg hover:bg-ottonomyBlue hover:shadow-md"
                            onClick={() => {
                              setDeletedTransitOrderId(item.orderId);
                              setOpenRemoveTransitOrderModal(true);
                              // deleteOrderTransit(item.orderId)
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14.078"
                              height="14.057"
                              viewBox="0 0 14.078 14.057"
                            >
                              <g
                                id="noun-delete-5593968"
                                transform="translate(-84 -14.395)"
                              >
                                <path
                                  id="Path_119"
                                  data-name="Path 119"
                                  d="M157.573,115.376H151.03a2.05,2.05,0,0,1-2.048-2.048v-9.646h.741v9.646a1.309,1.309,0,0,0,1.307,1.307h6.542a1.309,1.309,0,0,0,1.307-1.307v-9.646h.741v9.646a2.05,2.05,0,0,1-2.048,2.048Z"
                                  transform="translate(-63.262 -86.924)"
                                  fill="#dc6068"
                                />
                                <path
                                  id="Path_120"
                                  data-name="Path 120"
                                  d="M97.707,90.421H84.37a.37.37,0,0,1,0-.741H97.707a.37.37,0,0,1,0,.741Z"
                                  transform="translate(0 -73.293)"
                                  fill="#dc6068"
                                />
                                <path
                                  id="Path_121"
                                  data-name="Path 121"
                                  d="M259.818,16.758h-.741V15.6a.469.469,0,0,0-.468-.468h-2.621a.469.469,0,0,0-.468.468v1.153h-.741V15.6a1.211,1.211,0,0,1,1.209-1.209h2.621a1.211,1.211,0,0,1,1.209,1.209Z"
                                  transform="translate(-166.259)"
                                  fill="#dc6068"
                                />
                                <path
                                  id="Path_122"
                                  data-name="Path 122"
                                  d="M262.17,210.978a.37.37,0,0,1-.37-.37V205.17a.37.37,0,1,1,.741,0v5.437A.37.37,0,0,1,262.17,210.978Z"
                                  transform="translate(-173.095 -185.366)"
                                  fill="#dc6068"
                                />
                                <path
                                  id="Path_123"
                                  data-name="Path 123"
                                  d="M410.57,210.978a.37.37,0,0,1-.37-.37V205.17a.37.37,0,1,1,.741,0v5.437A.37.37,0,0,1,410.57,210.978Z"
                                  transform="translate(-317.568 -185.366)"
                                  fill="#dc6068"
                                />
                              </g>
                            </svg>
                          </button>
                        )}
                        <td></td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      className="text-2xl font-bold text-center text-textColor"
                      colSpan="6"
                    >
                      Oops! No orders in transit
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="d-flex-center">
          <div>
            <p className="countSize_p">
              {" "}
              {currentSize} of {totalcount}{" "}
            </p>
          </div>
          <div>
            {Paginationlength ? (
              <ThemeProvider theme={theme}>
                <Pagination
                  className="PaginationWrapper"
                  count={showcount}
                  page={page}
                  onChange={handleChangetwo}
                  sx={{ width: "100%" }}
                />
              </ThemeProvider>
            ) : null}
          </div>
        </div> */}

        <RemoveOrderModal
          openRemoveOrderModal={openRemoveOrderModal}
          setOpenRemoveOrderModal={setOpenRemoveOrderModal}
          deletedOrderId={deletedOrderId}
          setDeletedOrderId={setDeletedOrderId}
          deleteOrder={deleteOrder}
        />

        <RemoveTransitOrderModal
          openRemoveTransitOrder={openRemoveTransitOrder}
          setOpenRemoveTransitOrderModal={setOpenRemoveTransitOrderModal}
          deletedTransitOrderId={deletedTransitOrderId}
          setDeletedTransitOrderId={setDeletedTransitOrderId}
          deleteOrderTransit={deleteOrderTransit}
        />
      </div>
    </>
  );
}

export default OrderList;

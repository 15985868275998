import React, { useEffect } from "react";
import { useState } from "react";
import { ImStack } from "react-icons/im";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { dropofflocations, MapData, pickuplocations } from "../Data/MapData";
import Header from "../components/Header";
import DashboardSideBar from "./DashboardSideBar";
import DashboardBottomBar from "../components/DashboardBottomBar";
import AnalyticsPage from "../components/AnalyticsTopBar";
import { useHasPermissionInAnyFleet } from "../customHooks/PermissionHooks";
import Layout from "../components/Layout";

const Analytics = (props) => {
  const canSeeLogs = useHasPermissionInAnyFleet("mission-logs");
  const canSeeOrderAnalytics = useHasPermissionInAnyFleet("order-analytics");
  const [robotlistData, setrobotlistData] = useState();

  const fleetData = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    MapData.forEach((item, key) => {
      item.sno = key + 1;
    });
    pickuplocations.forEach((item, key) => {
      item.sno = key + 1;
    });
    dropofflocations.forEach((item, key) => {
      item.sno = key + 1;
    });
  }, []);

  const isopen = useSelector((state) => state.dashboardopenReducer);
  const { sidebarcollapse } = useSelector((state) => state.login);

  return (
    <>
          <Layout dashboardopen={props.dashboardopen} activeTab={props.activeTab} setActiveTab={props.setActiveTab}>

      <div className="container-fluid">
        <div className="row">
        {/* <div
          className={`hidden midLg:block ${
            sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"
          }`}
        >
            <DashboardSideBar
              dashboardopen={props.dashboardopen}
              active="analytics"
            />
            <DashboardBottomBar active="fleetpage" />
          </div> */}

          <div
            className={`${
              sidebarcollapse
                ? "collapseWidth"
                : "map_col_10"
            }`}
          >
            <div
              style={{ height: "100%" }}
              className={isopen.userlogindata ? "" : ""}
            >
              <div className="FleetView_page_Image_Button_wrapper_wholeMain">
                <div className="flex flex-col h-full FleetPage_Top_bar">
                  <AnalyticsPage
                    fleetData={
                      fleetData.state
                        ? fleetData.state.fleetData
                        : JSON.parse(localStorage.getItem("fleetData"))
                    }
                    robotlistData={robotlistData}
                  />
                </div>
              </div>
              <div className="map-icons ">
                <ImStack size={34} className="fill-square" />
                <AiOutlinePlus size={34} className="fill-circle" />
                <AiOutlineMinus size={34} className="fill-circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    </>
  );
};

export default Analytics;

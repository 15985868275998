import axios from "axios";
import { fleetConstants } from "../constants/fleet";
import axiosInstance from "../../config/axiosConfig";

export const fleetListAndRobotStatusByUserEmail =
  (email,auth,token) => async (dispatch) => {
    try {
      dispatch({
        type: fleetConstants.FLEET_LIST_AND_ROBOT_STATUS_BY_USER_EMAIL_REQUEST,
      });

      const  headers= {
        "Content-Type": "application/json",
      }
      if(auth){
        headers["x-api-key"]=auth
      }
      if(token){
        headers["Authorization"]= `Bearer ${token}`
      }
      const data = await axiosInstance.post(
        'fleet/v1/getFleetListAndRobotStatusByUserEmail',{
          emailId: email,}
      )
      // const data = await axios.post(
      //   `${process.env.REACT_APP_BASE_URL}fleet/v1/getFleetListAndRobotStatusByUserEmail`,
      //   {
      //     emailId: email,
      //   },
      //   {
      //    headers
      //   }
      // );

      if (data.data.status === 200) {
        let markers = [];
        data.data.fleet[0].robots.forEach((robotDetails) => {
          if (
            parseFloat(robotDetails.latitude) !== 0 &&
            parseFloat(robotDetails.longitude) !== 0
          ) {
            markers.push({
              position: {
                lat: parseFloat(robotDetails.latitude),
                lng: parseFloat(robotDetails.longitude),
                slamLat: parseFloat(robotDetails.latitudeSlam),
                slamLng: parseFloat(robotDetails.longitudeSlam),
                heading: parseFloat(robotDetails.heading),
                headingSlam: parseFloat(robotDetails.headingSlam),
              },
            });
          }
        });
        dispatch({
          type: fleetConstants.FLEET_LIST_AND_ROBOT_STATUS_BY_USER_EMAIL_SUCCESS,
          payload: {
            fleetData: data.data.fleet,
            markers: markers,
          },
        });
      } else throw Error(data.data.message);
    } catch (error) {
      dispatch({
        type: fleetConstants.FLEET_LIST_AND_ROBOT_STATUS_BY_USER_EMAIL_FAIL,
        payload: error.message,
      });
    }
  };


  export const destroySessionFleet = () => (dispatch) => {
    try {
      dispatch({
        type: fleetConstants.DESTROY_SESSION,
      });
    } catch (error) {
      // dispatch({
      //   type: robotConstants.ROBOT_DETAILS_FAIL,
      //   payload: error,
      // });
    }
  };

// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: fleetConstants.CLEAR_ERRORS,
  });
};

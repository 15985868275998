import React from 'react';
import { useNavigate } from 'react-router-dom';

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className='flex flex-col items-center justify-center text-textColor h-screen gap-y-3'>
      <h1>Unauthorized</h1>
      <p>You do not have permission to access this page. Contact Admin</p>
      <button className = "p-2 bg-ottonomyBlue rounded-lg" onClick={handleGoBack}>Go Back</button>
    </div>
  );
};

export default Unauthorized;

import { Height } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { MdOutlineClose } from "react-icons/md";

const Modal = ({
  isOpen,
  onClose,
  onSubmit,
  bgColor,
  children,
  title,
  titleColor,
  submitText,
  disabled = false,
  height = 500 ,
  width = 900
}) => {

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
            <div
              className={`flex flex-col gap-y-3 justify-between bg-${bgColor?`[${bgColor}]`:'primaryColor'} rounded-lg p-8 z-10 w-4/5 overflow-auto`}
            >
              <div className="flex justify-between">
                <div/>
                <p className={`text-${titleColor} text-2xl font-bold capitalize`}>{title}</p>
                <MdOutlineClose  onClick={onClose} className={`text-${titleColor} font-bold text-2xl`}/>
              </div>
              <div className="flex flex-col gap-y-3">
                {children}
              </div>
              <div className="flex justify-end">
                <button
                  className={`font-bold py-2 px-4 rounded ${disabled ? 'bg-gray-400 text-gray-600 cursor-not-allowed' : 'bg-ottonomyBlue text-white'}`}
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  {submitText}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

import { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [themeMode, setThemeMode] = useState(localStorage.getItem("theme") || 'Default');

useEffect(() => {
  document.documentElement.className = themeMode;
},[themeMode])
  const switchTheme = (theme) => {
    setThemeMode(theme);
    localStorage.setItem("theme",theme);

    // document.documentElement.className = theme;
  };

  return (
    <ThemeContext.Provider value={{ themeMode, switchTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  return useContext(ThemeContext);
}

import { MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { memo, useState } from "react";
import { Dropdown } from "react-bootstrap";
const style = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "19px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "#20212D",
  boxShadow: 24,
  p: "12px",
  borderRadius: "10px",
};

function CancelOrderConfirmModal({
  showActionLiveDropdown,
  textToDisplay,
  onConfirm,
  showConfirmationModal,
  setShowConfirmationModal,
}) {
  const [summonActionLive, setSummonActionLive] = useState("LIVE");

  const handleClose = (reason) => {
    setShowConfirmationModal(false);
  };
  return (
    <>
      <Modal
        BackdropProps={{
          timeout: 500,
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.65)", // Optional: Add a semi-transparent background color
          },
        }}
        disableEnforceFocus
        disableAutoFocus
        disableRestoreFocus
        open={showConfirmationModal}
        onHide={handleClose}
        onClose={handleClose}
        backdrop="static"
        keyboard="false"
      >
        <Box sx={style}>
          <div className="w-[325px] text-center text-textColor text-base font-bold font-['Poppins']">
            {textToDisplay}
          </div>
          {showActionLiveDropdown && (
            <div className="flex w-full justify-around">
              <label
                style={{
                  fontWeight: "600",
                  color: "#ffffff",
                }}
              >
                Summon Type
              </label>

              <Select
                variant="standard"
                labelId="actionLive-label"
                id="actionLive-select"
                name="actionLive"
                value={summonActionLive}
                label="actionLive"
                style={{
                  color: "#ffffff",
                  margin: "0",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
                onChange={(value) => setSummonActionLive(value.target.value)}
              >
                <MenuItem value="Select Summon Type" selected disabled>
                  Select Summon Type
                </MenuItem>
                <MenuItem value="LIVE">LIVE</MenuItem>
                <MenuItem value="TEST">TEST</MenuItem>
              </Select>
            </div>
          )}
          <div className="w-full h-11 justify-start items-start gap-4 inline-flex">
            <div
              className="w-full h-11 p-2.5 cursor-pointer bg-cyan-500 rounded-[7px] justify-center items-center gap-2.5 inline-flex"
              onClick={() => {
                setShowConfirmationModal(false);
                onConfirm(summonActionLive);
              }}
            >
              <span className="text-center text-textColor text-base font-bold font-['Poppins']">
                Yes
              </span>
            </div>
            <div
              className="w-full h-11 p-2.5 cursor-pointer bg-red-500 rounded-[7px] justify-center items-center gap-2.5 inline-flex"
              onClick={() => {
                handleClose();
              }}
            >
              <span className="text-center text-textColor text-base font-bold font-['Poppins']">
                No
              </span>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default memo(CancelOrderConfirmModal);
